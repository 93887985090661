/* eslint-disable camelcase */
import React from 'react';
import styled from '@emotion/styled';
import CherreDataUtil from '@biproxi/models/utils/CherreDataUtil';
import ICherreDataInsights from '@biproxi/models/interfaces/ICherreDataInsights';
import ICherreFieldValuePair from '@biproxi/models/interfaces/ICherreFieldValuePair';
import Colors, { ColorsType } from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import { media } from '../../utils/MediaQuery';
import CherreGenericInsights from './CherreDataInsights/CherreGenericInsights';
import Divider from '../../elements/Divider';

type InsightsContainerProps = {
  flexDirection?: string;
}

const InsightsContainer = styled.div<InsightsContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};

  ${media.print} {
    flex-direction: row;
  };
`;

type CherreInsightsCardProps = {
  backgroundColor?: ColorsType;
  padding: string;
  margin: string;
}

const CherreInsightsCard = styled.div<CherreInsightsCardProps>`
  box-sizing: border-box;
  padding: 16px;
  background: ${(props) => props.backgroundColor};
  width: 100%;
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  ${media.mobile} {
  width: 100%;
  }

  ${media.tablet} {
  width: 100%;
  }
`;

const CherreFieldValueContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type FieldValuePairProps = ICherreFieldValuePair

export const FieldValuePair: React.FC<FieldValuePairProps> = ({ field, value }) => (
  <CherreFieldValueContainer>
    <Text
      type={TextTypesEnum.Regular14}
      color={Colors.Grey500}
    >
      {field}
    </Text>
    <Text
      type={TextTypesEnum.Bold16}
      color={Colors.Grey900}
    >
      {value ?? '--'}
    </Text>
  </CherreFieldValueContainer>
);

export enum DataInsightFormatEnum {
  Card = 'Card',
  List = 'List'
}

export enum CherreInsightsSectionsEnum {
  LotInformation = 'Lot Information',
  LotSize = 'Lot Size',
  BuildingInfo = 'Building Info',
  Taxes = 'Taxes',
  PropertyDetails = 'Property Details',
  AdditionalPropertyDetails = 'Additional Property Details',
}

export enum CherreInsightsSectionsDataEnum {
  LotInformation = 'lotInformation',
  LotSize = 'lotSize',
  BuildingInfo = 'buildingInfo',
  Taxes = 'taxes',
  PropertyDetails = 'propertyDetails',
  AdditionalPropertyDetails = 'propertyDetailsAdditionalInformation',
}

type PDPCherreDataInsightsProps = {
  insights: ICherreDataInsights;
  backgroundColor?: ColorsType;
  dataFormat?: DataInsightFormatEnum;
  sections: string[];
  flexDirection?: string;
  padding?: string;
  margin?: string;
  divideInsightSections?: boolean;
};

const PDPCherreDataInsights: React.FC<PDPCherreDataInsightsProps> = ({
  insights,
  backgroundColor = Colors.Grey50,
  dataFormat = DataInsightFormatEnum.Card,
  sections,
  flexDirection = 'column',
  padding = '16px',
  margin = '8px 0px',
  divideInsightSections = false,
}) => {
  /** Render */
  const formattedInsights = CherreDataUtil.formatCherreInsights(insights);
  if (!Array.isArray(sections)) {
    sections = [sections];
  }

  return (
    <InsightsContainer flexDirection={flexDirection}>
      <>
        {sections?.map((section, index) => (
          <React.Fragment key={index}>
            <CherreInsightsCard backgroundColor={backgroundColor} padding={padding} margin={margin}>
              <CherreGenericInsights title={CherreInsightsSectionsEnum[section]} data={formattedInsights[CherreInsightsSectionsDataEnum[section]]} dataFormat={dataFormat} />
            </CherreInsightsCard>
            {divideInsightSections && (
            <Divider />
            )}
          </React.Fragment>
        ))}
      </>
    </InsightsContainer>
  );
};

export default PDPCherreDataInsights;
