/* eslint-disable no-restricted-syntax */
import CherreDictionaryUtil from './CherreDictionaryUtil';
import ICherreDataInsights from '../interfaces/ICherreDataInsights';
import ICherreDataDemographics from '../interfaces/ICherreDataDemographics';
import ICherreFieldValuePair from '../interfaces/ICherreFieldValuePair';
// import { CherreInsightsSectionData } from '../enums/CherreInsightsSectionsEnum';

export type FormattedInsights = {
  lotInformation?: ICherreFieldValuePair[];
  lotSize?: ICherreFieldValuePair[];
  buildingInfo?: ICherreFieldValuePair[];
  taxes?: ICherreFieldValuePair[];
  propertyDetails?: ICherreFieldValuePair[];
  propertyDetailsAdditionalInformation?: ICherreFieldValuePair[];
}

export type FormattedDemographics = {
  income?: ICherreFieldValuePair[];
  crime?: ICherreFieldValuePair[];
  population?: ICherreFieldValuePair[];
  employee?: ICherreFieldValuePair[];
  education?: ICherreFieldValuePair[];
}

function formatCherreInsights(insights: ICherreDataInsights, specificInsight?: string): FormattedInsights {
  const formattedInsights = {
    lotInformation: [
      {
        field: 'APN',
        value: insights?.assessor_parcel_number_raw ?? null,
      },
      {
        field: 'Property type',
        value: insights?.property_group_type ?? null,
      },
      {
        field: 'Property Use',
        value: CherreDictionaryUtil.lookup('property_use_standardized_code', insights?.property_use_standardized_code ?? null),
      },
      {
        field: 'Last Sale',
        value: insights?.last_sale_amount ? `$${insights?.last_sale_amount?.toLocaleString()}` : null,
      },
      {
        field: 'Zone',
        value: insights?.zone_code ?? null,
      },
      {
        field: 'Last County Assessor Update',
        value: insights?.tax_assessor_last_update_date ?? null,
      },
    ],
    lotSize: [
      {
        field: 'Size',
        value: insights?.lot_size_sq_ft ? `${insights?.lot_size_sq_ft?.toLocaleString()} sqft` : null,
      },
      {
        field: 'Width',
        value: insights?.lot_width ? `${insights?.lot_width?.toLocaleString()} sqft` : null,
      },
      {
        field: 'Depth',
        value: insights?.lot_depth_ft ? `${insights?.lot_depth_ft?.toLocaleString()} sqft` : null,
      },
      {
        field: 'Gross Area',
        value: insights?.gross_sq_ft ? `${insights?.gross_sq_ft?.toLocaleString()} sqft` : null,
      },
      {
        field: 'Lot size (acres)',
        value: insights?.lot_size_acre ?? null,
      },
    ],
    buildingInfo: [
      {
        field: 'Year Built',
        value: insights?.year_built ? `${insights?.year_built}` : null,
      },
      {
        field: 'Number of Units',
        value: `${insights?.units_count}` ?? null,
      },
      {
        field: 'Building Area',
        value: insights?.building_sq_ft ? `${insights?.building_sq_ft?.toLocaleString()} sqft` : null,
      },
    ],
    taxes: [
      {
        field: 'Current Tax',
        value: insights?.tax_bill_amount ? `$${insights?.tax_bill_amount?.toLocaleString()}` : null,
      },
      {
        field: 'Jurisdiction',
        value: insights?.jurisdiction ?? null,
      },
      {
        field: 'Assessed Total Value',
        value: insights?.assessed_value_total ? `$${insights?.assessed_value_total?.toLocaleString()}` : null,
      },
      {
        field: 'Assessed Tax Year',
        value: insights?.assessed_tax_year ?? null,
      },
      {
        field: 'Assessed Improvements',
        value: insights?.assessed_value_improvements ? `$${insights?.assessed_value_improvements?.toLocaleString()}` : null,
      },
      {
        field: 'Assessed Land Value',
        value: insights?.assessed_value_land ? `$${insights?.assessed_value_land?.toLocaleString()}` : null,
      },
      {
        field: 'Market Total',
        value: insights?.market_value_total ? `$${insights?.market_value_total?.toLocaleString()}` : null,
      },
      {
        field: 'Market Value Year',
        value: insights?.market_value_year ?? null,
      },
      {
        field: 'Market Improvements',
        value: insights?.market_value_improvements ? `$${insights?.market_value_improvements?.toLocaleString()}` : null,
      },
      {
        field: 'Market Value Land',
        value: insights?.the_value_land ? `$${insights?.the_value_land?.toLocaleString()}` : null,
      },
    ],
    propertyDetails: [
      {
        field: 'Upper Floor Square Footage',
        value: insights?.upper_floor_sq_ft ? `${insights?.upper_floor_sq_ft?.toLocaleString()} sqft` : null,
      },
      {
        field: 'Sewer Usage',
        value: CherreDictionaryUtil.lookup('sewer_usage_code', insights?.sewer_usage_code ?? null),
      },
      {
        field: 'Water Usage',
        value: CherreDictionaryUtil.lookup('water_source_code', insights?.water_source_code ?? null),
      },
      {
        field: 'Construction',
        value: CherreDictionaryUtil.lookup('construction_code', insights?.construction_code ?? null),
      },
      {
        field: 'Fire Resistance Class',
        value: CherreDictionaryUtil.lookup('fire_resistance_class_code', insights?.fire_resistance_class_code ?? null),
      },
      {
        field: 'Exterior',
        value: CherreDictionaryUtil.lookup('exterior_code', insights?.exterior_code ?? null),
      },
      {
        field: 'Topography',
        value: CherreDictionaryUtil.lookup('topography_code', insights?.topography_code ?? null),
      },
    ],
    propertyDetailsAdditionalInformation: [
      {
        field: 'Basement',
        value: insights?.basement_sq_ft ?? null,
      },
      {
        field: 'HVAC',
        value: CherreDictionaryUtil.lookup('hvacc_heating_code', insights?.hvacc_heating_code ?? null),
      },
      {
        field: 'Cooling',
        value: CherreDictionaryUtil.lookup('hvacc_cooling_code', insights?.hvacc_cooling_code ?? null),
      },
      {
        field: 'Has Elevator',
        value: insights?.has_elevator ?? null,
      },
      {
        field: 'Porch',
        value: CherreDictionaryUtil.lookup('porch_code', insights?.porch_code ?? null),
      },
      {
        field: 'Fence',
        value: CherreDictionaryUtil.lookup('fence_code', insights?.fence_code ?? null),
      },
      {
        field: 'Fence area',
        value: insights?.fence_sq_ft ?? null,
      },
      {
        field: 'Has Canopy',
        value: insights?.has_canopy ?? null,
      },
      {
        field: 'Canopy Square Feet',
        value: insights?.canopy_sq_ft ? `${insights?.canopy_sq_ft} sqft` : null,
      },
    ],
  };
  if (specificInsight) {
    return formattedInsights[specificInsight];
  }
  return formattedInsights;
}

function formatCherreDemographics(demographics: ICherreDataDemographics, priorYearDemographics: ICherreDataDemographics): FormattedDemographics {
  const formattedDemographics = {
    income: [
      {
        field: 'Median Income',
        value: [demographics?.median_household_income ?? null, priorYearDemographics?.median_household_income ?? null],
      },
      {
        field: '$0 - 10K',
        value: demographics?.household_income_0_10_count ?? null,
      },
      {
        field: '$10 - 15K',
        value: demographics?.household_income_10_15_count ?? null,
      },
      {
        field: '$15 - 20K',
        value: demographics?.household_income_15_20_count ?? null,
      },
      {
        field: '$20 - 25K',
        value: demographics?.household_income_20_25_count ?? null,
      },
      {
        field: '$25 - 30K',
        value: demographics?.household_income_25_30_count ?? null,
      },
      {
        field: '$30 - 35K',
        value: demographics?.household_income_30_35_count ?? null,
      },
      {
        field: '$35 - 40K',
        value: demographics?.household_income_35_40_count ?? null,
      },
      {
        field: '$40 - 45K',
        value: demographics?.household_income_40_45_count ?? null,
      },
      {
        field: '$45 - 50K',
        value: demographics?.household_income_45_50_count ?? null,
      },
      {
        field: '$50 - 60K',
        value: demographics?.household_income_50_60_count ?? null,
      },
      {
        field: '$60 - 75K',
        value: demographics?.household_income_60_75_count ?? null,
      },
      {
        field: '$75 - 100',
        value: demographics?.household_income_75_100_count ?? null,
      },
      {
        field: '$100 - 125K',
        value: demographics?.household_income_100_125_count ?? null,
      },
      {
        field: '$125 - 150K',
        value: demographics?.household_income_125_150_count ?? null,
      },
      {
        field: '$150 - 200K',
        value: demographics?.household_income_150_200_count ?? null,
      },
      {
        field: '$200 - 250K',
        value: demographics?.household_income_200_250_count ?? null,
      },
      {
        field: '$250 - 300K',
        value: demographics?.household_income_150_200_count ?? null,
      },
      {
        field: '$500K+',
        value: demographics?.household_income_over_500_count ?? null,
      },
    ],
    crime: [
      {
        field: 'Total Crime Risk',
        value: [demographics?.crime_total_risk ?? null, priorYearDemographics?.crime_total_risk ?? null],
      },
      {
        field: 'Assault Risk',
        value: demographics?.crime_assault_risk ?? null,
      },
      {
        field: 'Burglary Risk',
        value: demographics?.crime_burglary_risk ?? null,
      },
      {
        field: 'Larceny Risk',
        value: demographics?.crime_larceny_risk ?? null,
      },
      {
        field: 'Murder Risk',
        value: demographics?.crime_murder_risk ?? null,
      },
      {
        field: 'Motor Vehicle Risk',
        value: demographics?.crime_motor_vehicle_risk ?? null,
      },
      {
        field: 'Personal Risk',
        value: demographics?.crime_personal_risk ?? null,
      },
      {
        field: 'Property Risk',
        value: demographics?.crime_property_risk ?? null,
      },
      {
        field: 'Rape Risk',
        value: demographics?.crime_rape_risk ?? null,
      },
      {
        field: 'Robbery Risk',
        value: demographics?.crime_robbery_risk ?? null,
      },
    ],
    population: [
      {
        field: 'Median Age',
        value: [demographics?.population_median_age ?? null, priorYearDemographics?.population_median_age ?? null],
      },
      {
        field: '0 - 4',
        value: demographics?.population_age_00_04_count ?? null,
      },
      {
        field: '5 - 9',
        value: demographics?.population_age_05_09_count ?? null,
      },
      {
        field: '10 - 14',
        value: demographics?.population_age_10_14_count ?? null,
      },
      {
        field: '15 - 19',
        value: demographics?.population_age_15_19_count ?? null,
      },
      {
        field: '20 - 24',
        value: demographics?.population_age_20_24_count ?? null,
      },
      {
        field: '25 - 29',
        value: demographics?.population_age_25_29_count ?? null,
      },
      {
        field: '30 - 34',
        value: demographics?.population_age_30_34_count ?? null,
      },
      {
        field: '35 - 39',
        value: demographics?.population_age_35_39_count ?? null,
      },
      {
        field: '40 - 44',
        value: demographics?.population_age_40_44_count ?? null,
      },
      {
        field: '45 - 49',
        value: demographics?.population_age_45_49_count ?? null,
      },
      {
        field: '50 - 54',
        value: demographics?.population_age_50_54_count ?? null,
      },
      {
        field: '55 - 59',
        value: demographics?.population_age_55_59_count ?? null,
      },
      {
        field: '60 - 64',
        value: demographics?.population_age_60_64_count ?? null,
      },
      {
        field: '65 - 69',
        value: demographics?.population_age_65_69_count ?? null,
      },
      {
        field: '70 - 74',
        value: demographics?.population_age_70_74_count ?? null,
      },
      {
        field: '75 - 79',
        value: demographics?.population_age_75_79_count ?? null,
      },
      {
        field: '80 - 84',
        value: demographics?.population_age_80_84_count ?? null,
      },
      {
        field: '85+',
        value: demographics?.population_age_over_85_count ?? null,
      },
    ],
    employee: [
      {
        field: 'Business/Finance',
        value: demographics?.employee_business_finance_count ?? null,
      },
      {
        field: 'The Arts',
        value: demographics?.employee_arts_count ?? null,
      },
      {
        field: 'Cleaning/Maintenance',
        value: demographics?.employee_cleaning_maintenance_count ?? null,
      },
      {
        field: 'Admin/Support',
        value: demographics?.employee_admin_support_count ?? null,
      },
      {
        field: 'Computes/Math/Science',
        value: demographics?.employee_computer_math_science_count ?? null,
      },
      {
        field: 'Construction',
        value: demographics?.employee_construction_count ?? null,
      },
      {
        field: 'Social Services',
        value: demographics?.employee_social_services_count ?? null,
      },
      {
        field: 'Education',
        value: demographics?.employee_education_count ?? null,
      },
      {
        field: 'Architecture/Engineering',
        value: demographics?.employee_architecture_engineering_count ?? null,
      },
      {
        field: 'Food Service',
        value: demographics?.employee_food_service_count ?? null,
      },
      {
        field: 'Healthcare',
        value: demographics?.employee_healthcare_count ?? null,
      },
      {
        field: 'Healthcare Practitioner',
        value: demographics?.employee_healthcare_practitioner_count ?? null,
      },
      {
        field: 'Repair',
        value: demographics?.employee_repair_count ?? null,
      },
      {
        field: 'Military',
        value: demographics?.employee_military_count ?? null,
      },
      {
        field: 'Personal Care',
        value: demographics?.employee_personal_care_count ?? null,
      },
      {
        field: 'Farming',
        value: demographics?.employee_farming_count ?? null,
      },
      {
        field: 'Production',
        value: demographics?.employee_production_count ?? null,
      },
      {
        field: 'Protective Service',
        value: demographics?.employee_protective_service_count ?? null,
      },
      {
        field: 'Sales',
        value: demographics?.employee_sales_count ?? null,
      },
      {
        field: 'Social Science',
        value: demographics?.employee_social_science_count ?? null,
      },
      {
        field: 'Transportation',
        value: demographics?.employee_transportation_count ?? null,
      },
      {
        field: 'Executive',
        value: demographics?.employee_executive_count ?? null,
      },
      {
        field: 'Unclassified',
        value: demographics?.employee_unclassified_count ?? null,
      },
    ],
    education: [
      {
        field: 'Less than 9 years',
        value: demographics?.education_less_than_9_count ?? null,
      },
      {
        field: 'Some high school',
        value: demographics?.education_some_high_school_count ?? null,
      },
      {
        field: 'High school',
        value: demographics?.education_high_school_graduate_count ?? null,
      },
      {
        field: 'Some college',
        value: demographics?.education_some_college_count ?? null,
      },
      {
        field: 'Associate\'s degree',
        value: demographics?.education_associate_degree_count ?? null,
      },
      {
        field: 'Bachelor\'s degree',
        value: demographics?.education_bachelors_degree_count ?? null,
      },
      {
        field: 'Graduate degree',
        value: demographics?.education_graduate_degree_count ?? null,
      },
    ],
  };
  return formattedDemographics;
}

function formatYearOverYearChangeForCherreDemographicsData(currentYearValue: number, priorYearValue: number): number {
  const valueChange = currentYearValue - priorYearValue;
  const yearOverYearChange = (valueChange / currentYearValue) * 100;
  return yearOverYearChange;
}

const CherreDataUtil = {
  formatCherreInsights,
  formatCherreDemographics,
  formatYearOverYearChangeForCherreDemographicsData,
};

export default CherreDataUtil;
