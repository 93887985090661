import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ReactTooltip from 'react-tooltip';
import { useDebounceCallback } from '@react-hook/debounce';
import ValuationUtil from '../../utils/ValuationUtil';
import IComputedValues from '../../models/interfaces/IComputedValues';
import NextUtil from '../../utils/NextUtil';
import Flex from '../../elements/Flex';
import Text, { TextTypesEnum } from '../../elements/Text';
import Input, { InputTypesEnum } from '../../elements/Input';
import Divider, { DividerTypesEnum } from '../../elements/Divider';
import Icon, { Icons } from '../../elements/Icon';
import Colors from '../../styles/Colors';
import { media, useMobileMedia } from '../../utils/MediaQuery';

const Container = styled.div``;

const InputSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;

  ${media.mobile} {
    width: 100%;
  }
`;

const InputWrapper = styled.div<{margin?: string}>`
  margin: ${(props) => (props.margin)};

  ${media.mobile} {
    margin: 8px 0px;
    width: 100%;
  }
`;

const DisplaySectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

const ValuationMetricsCard = styled.div`
  padding: 16px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  ${media.mobile} {
    border: none;
    padding: 0px;
  }
`;

const ValuationMetricsCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  ${media.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

enum ValuationTooltipsEnum {
  LoanAmount = 'The Loan Amount is the purchase price minus the down payment.',
  AnnualDebtService = 'Debt Service is the cash required to pay back the principal and interest of outstanding debt over a given time period.',
  AnnualCashFlow = 'Cash Flow is the net operating income minus the debt service.',
  DSCR = 'Debt Service Coverage Ratio (DSCR) is a measure of cash flow available to pay current debt obligations and is calculated by dividing the Annual Debt Service by Net Operating Income.',
  CapRate = 'Capitalization Rate (Cap Rate) is calculated by dividing a property\'s Net Operating Income by the current market value or your anticipated Purchase Price.',
  ROI = 'The Return on Investment or cash-on-cash return measures the amount of cash flow relative to the amount of cash invested in a property investment and is calculated by dividing the Down Payment from the Annual Cash Flow.',
}

type PDPValuationCalculatorProps = {
    id?: string
    listing: IListingGraphQL
};

const PDPValuationCalculator: React.FC<PDPValuationCalculatorProps> = ({ id, listing }) => {
  /** State */
  const [purchasePrice, setPurchasePrice] = React.useState<number>(listing.guidance.askingPrice);
  const [operatingIncome, setOperatingIncome] = React.useState<number>(0);
  const [downPayment, setDownPayment] = React.useState<number>(listing.guidance.askingPrice * 0.2);
  const [interestRate, setInterestRate] = React.useState<number>(3);
  const [term, setTerm] = React.useState<number>(30);
  const [computedValues, setComputedValues] = React.useState<IComputedValues>({});

  /** Hooks */
  const isMobile = useMobileMedia();

  /** Effects */
  if (NextUtil.hasWindow()) {
    React.useLayoutEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  }

  const setComputedValuesDebounced = useDebounceCallback(setComputedValues, 400);

  React.useEffect(() => {
    setComputedValuesDebounced(ValuationUtil.computeValues(purchasePrice, operatingIncome, downPayment, interestRate, term));
  }, [purchasePrice, operatingIncome, downPayment, interestRate, term]);

  /** Render */
  return (
    <Container id={id}>
      <Text
        type={TextTypesEnum.Bold18}
        color={Colors.Black}
        margin="0px 0px 24px 0px"
      >
        Valuation Calculator
      </Text>
      <Flex direction={isMobile ? 'column' : 'row'} margin={isMobile ? null : '0px 0px 24px 0px'}>
        <InputSectionContainer>
          <Flex direction={isMobile ? 'column' : 'row'} margin={isMobile ? null : '0px 0px 16px 0px'}>
            <InputWrapper margin="0px 12px 0px 0px">
              <Input
                label="Purchase Price"
                value={purchasePrice.toString()}
                onChange={(e) => setPurchasePrice(parseFloat(e.currentTarget.value.replace(/,/g, '')))}
                inputType={InputTypesEnum.Currency}
              />
            </InputWrapper>
            <InputWrapper margin="0px 0px 0px 12px">
              <Input
                label="Net Operating Income"
                placeholder="100,000"
                value={operatingIncome.toString()}
                onChange={(e) => setOperatingIncome(parseFloat(e.currentTarget.value.replace(/,/g, '')))}
                inputType={InputTypesEnum.Currency}
              />
            </InputWrapper>
          </Flex>
          <InputWrapper margin="0px 0px 24px 0px">
            <Input
              label="Down Payment"
              placeholder="Enter an amount"
              value={downPayment.toString()}
              onChange={(e) => setDownPayment(parseFloat(e.currentTarget.value.replace(/,/g, '')))}
              inputType={InputTypesEnum.Number}
              unit={computedValues.downPaymentPercent ? `${computedValues.downPaymentPercent.toLocaleString(undefined, { maximumFractionDigits: 2 })}%` : ' %'}
            />
          </InputWrapper>
          <Flex
            direction={isMobile ? 'column' : 'row'}
            margin="0px 0px 24px 0px"
          >
            <InputWrapper margin="0px 12px 0px 0px">
              <Input
                label="Interest Rate"
                placeholder="3"
                value={interestRate.toString()}
                onChange={(e) => setInterestRate(e.currentTarget.value)}
                inputType={InputTypesEnum.Number}
                unit="%"
              />
            </InputWrapper>
            <InputWrapper margin="0px 0px 0px 12px">
              <Input
                label="Term"
                placeholder="30"
                value={term.toString()}
                onChange={(e) => setTerm(parseFloat(e.currentTarget.value.replace(/,/g, '')))}
                inputType={InputTypesEnum.Number}
                unit="years"
              />
            </InputWrapper>
          </Flex>
        </InputSectionContainer>
        {isMobile ? (
          <div>
            <Divider type={DividerTypesEnum.Horizontal} color={Colors.Grey200} margin="32px 0px" />
          </div>
        ) : (
          <div>
            <Divider type={DividerTypesEnum.Vertical} color={Colors.Grey200} margin="0px 40px" />
          </div>
        )}
        <DisplaySectionContainer>
          <Flex direction="column">
            <Flex>
              <Text
                type={TextTypesEnum.Regular14}
                color={Colors.Grey500}
              >
                Loan Amount
              </Text>
              <Icon
                icon={Icons.InfoCircleRegular}
                color={`${Colors.Grey400}`}
                margin="4px 0px 0px 8px"
                width="16px"
                height="16px"
                size={16}
                transitionDuration="0.0s"
                tip={ValuationTooltipsEnum.LoanAmount}
                data-place="top"
                data-multiline
              />
            </Flex>
            <Text
              type={TextTypesEnum.Bold30}
              color={Colors.Grey900}
            >
              {computedValues.loanAmount ? `$${computedValues.loanAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '--'}
            </Text>
          </Flex>
          <Flex direction="column">
            <Flex>
              <Text
                type={TextTypesEnum.Regular14}
                color={Colors.Grey500}
              >
                Annual Debt Service
              </Text>
              <Icon
                icon={Icons.InfoCircleRegular}
                color={`${Colors.Grey400}`}
                margin="4px 0px 0px 8px"
                width="16px"
                height="16px"
                size={16}
                transitionDuration="0.0s"
                tip={ValuationTooltipsEnum.AnnualDebtService}
                data-place="top"
                data-multiline
              />
            </Flex>
            <Text
              type={TextTypesEnum.Bold24}
              color={Colors.Grey900}
            >
              {computedValues.annualPayment ? `$${computedValues.annualPayment.toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '--'}
            </Text>
            <Text
              type={TextTypesEnum.Bold16}
              color={Colors.Grey900}
            >
              {computedValues.monthlyPayment ? `$${computedValues.monthlyPayment.toLocaleString(undefined, { maximumFractionDigits: 2 })} /mo` : '--'}
            </Text>
          </Flex>

          <Flex direction="column">
            <Flex>
              <Text
                type={TextTypesEnum.Regular14}
                color={Colors.Grey500}
              >
                Annual Cash Flow
              </Text>
              <Icon
                icon={Icons.InfoCircleRegular}
                color={`${Colors.Grey400}`}
                margin="4px 0px 0px 8px"
                width="16px"
                height="16px"
                size={16}
                transitionDuration="0.0s"
                tip={ValuationTooltipsEnum.AnnualCashFlow}
                data-place="top"
                data-multiline
              />
            </Flex>
            <Text
              type={TextTypesEnum.Bold24}
              color={Colors.Grey900}
            >
              {computedValues.annualCashFlow ? `$${computedValues.annualCashFlow.toLocaleString(undefined, { maximumFractionDigits: 2 })}` : '--'}
            </Text>
            <Text
              type={TextTypesEnum.Bold16}
              color={Colors.Grey900}
            >
              {computedValues.monthlyCashFlow ? `$${computedValues.monthlyCashFlow.toLocaleString(undefined, { maximumFractionDigits: 2 })} /mo` : '--'}
            </Text>
          </Flex>
        </DisplaySectionContainer>
      </Flex>
      {isMobile && (
      <div>
        <Divider type={DividerTypesEnum.Horizontal} color={Colors.Grey200} margin="32px 0px" />
      </div>
      )}
      <ValuationMetricsCard>
        <Text
          type={TextTypesEnum.Bold16}
          color={Colors.Black}
          margin="0px 0px 16px"
        >
          Valuation Metrics
        </Text>
        <ValuationMetricsCardGrid>
          <Flex direction="column">
            <Flex>
              <Text
                type={TextTypesEnum.Regular14}
                color={Colors.Grey500}
              >
                DSCR
              </Text>
              <Icon
                icon={Icons.InfoCircleRegular}
                color={`${Colors.Grey400}`}
                margin="4px 0px 0px 8px"
                width="16px"
                height="16px"
                size={16}
                transitionDuration="0.0s"
                tip={ValuationTooltipsEnum.DSCR}
                data-place="top"
                data-multiline
              />
            </Flex>
            <Text
              type={TextTypesEnum.Bold24}
              color={Colors.Grey900}
              margin={isMobile ? '0px 0px 32px 0px' : null}
            >
              {computedValues.debtServiceCoverage ? computedValues.debtServiceCoverage.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '0'}
            </Text>
          </Flex>
          <Flex>
            {!isMobile && (
              <div>
                <Divider type={DividerTypesEnum.Vertical} color={Colors.Grey200} margin="0px 24px 0px 0px" />
              </div>
            )}
            <Flex direction="column">
              <Flex>
                <Text
                  type={TextTypesEnum.Regular14}
                  color={Colors.Grey500}
                >
                  Cap Rate
                </Text>
                <Icon
                  icon={Icons.InfoCircleRegular}
                  color={`${Colors.Grey400}`}
                  margin="4px 0px 0px 8px"
                  width="16px"
                  height="16px"
                  size={16}
                  transitionDuration="0.0s"
                  tip={ValuationTooltipsEnum.CapRate}
                  data-place="top"
                  data-multiline
                />
              </Flex>
              <Text
                type={TextTypesEnum.Bold24}
                color={Colors.Grey900}
                margin={isMobile ? '0px 0px 32px 0px' : null}
              >
                {computedValues.capRate ? `${(computedValues.capRate).toLocaleString(undefined, { maximumFractionDigits: 2 })}%` : '0%'}
              </Text>
            </Flex>
          </Flex>
          <Flex>
            {!isMobile && (
              <div>
                <Divider type={DividerTypesEnum.Vertical} color={Colors.Grey200} margin="0px 24px 0px 0px" />
              </div>
            )}
            <Flex direction="column">
              <Flex>
                <Text
                  type={TextTypesEnum.Regular14}
                  color={Colors.Grey500}
                >
                  ROI
                </Text>
                <Icon
                  icon={Icons.InfoCircleRegular}
                  color={`${Colors.Grey400}`}
                  margin="4px 0px 0px 8px"
                  width="16px"
                  height="16px"
                  size={16}
                  transitionDuration="0.0s"
                  tip={ValuationTooltipsEnum.ROI}
                  data-place="top"
                  data-multiline
                />
              </Flex>
              <Text
                type={TextTypesEnum.Bold24}
                color={Colors.Grey900}
              >
                {computedValues.returnOnInvestment ? `${(computedValues.returnOnInvestment).toLocaleString(undefined, { maximumFractionDigits: 2 })}%` : '0%'}
              </Text>
            </Flex>
          </Flex>
        </ValuationMetricsCardGrid>
      </ValuationMetricsCard>
    </Container>
  );
};

export default PDPValuationCalculator;
