enum PDPSectionsEnum {
  PortfolioAddresses = 'Addresses',
  Details = 'Details',
  Documents = 'Documents',
  Highlights = 'Highlights',
  Description = 'Description',
  Contacts = 'Contacts',
  Valuation = 'Valuation',
  Location = 'Location',
  DataInsights = 'Data Insights',
}

export default PDPSectionsEnum;
