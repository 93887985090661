import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import Skeleton from 'react-loading-skeleton';
import Button, { ButtonSizesEnum } from '../../elements/Button';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import {
  media, useMobileMedia,
} from '../../utils/MediaQuery';
import useRequireAuthentication from '../../hooks/useRequireAuthentication.hook';
import useHandleRouteChange from '../../hooks/useHandleRouteChange.hook';
import { AppState, useAppSelector } from '../../redux/store';

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: ${((props) => props.backgroundColor)};
  border-radius: 16px;
  margin: 0 0 24px 0;

  ${media.mobile} {
    flex-direction: column;
    border-radius: 0px;
    margin: 0;
    height: 130px
  }
`;

const SkeletonContainer = styled.div`
  margin: 0 0 24px 0;

  ${media.mobile} {
    flex-direction: column;
    border-radius: 0px;
    margin: 0;
    height: 130px
  }
`;

type ContainerProps = {
  backgroundColor?: string;
}

type PDPDataExplorerProps = {
    listing: IListingGraphQL;
    isUserLoggedIn?: boolean
}

const PDPDataExplorer: React.FC<PDPDataExplorerProps> = ({
  listing,
  isUserLoggedIn,
}) => {
  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  /** Contentful Variables */
  const {
    contentful: {
      brandCollection: {
        colors,
        fetched,
      },
    },
  } = useAppSelector((state: AppState) => state);
  const [containerLoad, setContainerLoad] = React.useState(true);
  const backgroundColor = colors.Brand100 || colors.Blurple100;

  React.useEffect(() => {
    if (fetched) {
      setContainerLoad(false);
    }
  }, [fetched]);

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */
  /** State */
  const { coordinates } = listing.address?.location;

  /** Hooks */
  const isMobile = useMobileMedia();
  const requireAuthentication = useRequireAuthentication();
  const [loading, setLoading] = React.useState(false);
  const handleRouteChange = useHandleRouteChange();

  return (containerLoad
    ? (
      <SkeletonContainer style={{ width: '100%', height: '4%' }}>
        <Skeleton
          width="100%"
          height="100%"
          style={{ backgroundColor: Colors.Grey300, borderRadius: '10px' }}
        />
      </SkeletonContainer>
    ) : (
      <Container backgroundColor={backgroundColor}>
        <Text
          type={isMobile ? TextTypesEnum.Regular14 : TextTypesEnum.Regular18}
          margin={isMobile ? '0 0 16px 0' : '0'}
          color={Colors.Brand900 || Colors.Blurple900}
          align={isMobile ? 'center' : null}
        >
          {isUserLoggedIn ? (
            'Explore property data, visibility into tax record data, demographic information, and more in'

          ) : (
            'Login to explore property data, visibility into tax record data, demographic information, and more in'
          )}
          {' '}
          <b>Data Explorer.</b>
        </Text>
        <Button
          text={isUserLoggedIn ? 'Open Data Explorer' : 'Login'}
          size={ButtonSizesEnum.Medium}
          isLoading={loading}
          onClick={() => requireAuthentication(() => {
            setLoading(true);
            handleRouteChange('/app/dashboard/data-explorer', { location: coordinates });
          })}
          margin={isMobile ? '0' : '0 0 0 24px'}
        />
      </Container>
    )
  );
};

export default PDPDataExplorer;
