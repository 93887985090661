/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import styled from '@emotion/styled';
import Colors, { ColorsType } from '../styles/Colors';
import { media } from '../utils/MediaQuery';

type ContainerProps = {
  background: ColorsType;
  size: CenteredPageLayoutSizeEnum;
  'data-cy': string;
}

const Container = styled.div<ContainerProps>`
  position: absolute;
  width: 100%;
  min-height: ${(props) => {
    switch (props.size) {
      case CenteredPageLayoutSizeEnum.ExtraSmall:
        return '100vh';
      case CenteredPageLayoutSizeEnum.Small:
        return '100vh';
      case CenteredPageLayoutSizeEnum.PDP:
        return '100vh';
      case CenteredPageLayoutSizeEnum.Regular:
        return '100vh';
      case CenteredPageLayoutSizeEnum.FullScreen:
        return '100%';
      case CenteredPageLayoutSizeEnum.DataExplorer:
        return '100%';
      default:
        return '100vh';
    }
  }};
  background-color: ${(props) => props.background};
  data-cy: ${(props) => props['data-cy']};
`;

type ContentProps = {
  size: CenteredPageLayoutSizeEnum;
}

const Content = styled.div<ContentProps>`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding: ${(props) => {
    switch (props.size) {
      case CenteredPageLayoutSizeEnum.ExtraSmall:
        return '0 0 120px';
      case CenteredPageLayoutSizeEnum.Small:
        return '0 0 120px';
      case CenteredPageLayoutSizeEnum.PDP:
        return '0 0 120px';
      case CenteredPageLayoutSizeEnum.Regular:
        return '0 0 120px';
      case CenteredPageLayoutSizeEnum.FullScreen:
        return '0';
      case CenteredPageLayoutSizeEnum.DataExplorer:
        return '0';
      default:
        return '0 0 120px';
    }
  }};
`;

type ContentPaddingProps = {
  size: CenteredPageLayoutSizeEnum,
};

const ContentPadding = styled.div<ContentPaddingProps>`
  position: relative;
  width: 100%;
  margin: 24px 40px 0;
  width: fill-available;
  max-width: ${(props) => {
    switch (props.size) {
      case CenteredPageLayoutSizeEnum.ExtraSmall:
        return '992px';
      case CenteredPageLayoutSizeEnum.Small:
        return '1120px';
      case CenteredPageLayoutSizeEnum.PDP:
        return '1240px';
      case CenteredPageLayoutSizeEnum.Regular:
        if (media.desktopXL) return '1700px';
        return '1312px';
      case CenteredPageLayoutSizeEnum.FullScreen:
        return '100%';
      case CenteredPageLayoutSizeEnum.DataExplorer:
        return '100%';
      default:
        return '1120px';
    }
  }};
  margin: ${(props) => {
    switch (props.size) {
      case CenteredPageLayoutSizeEnum.ExtraSmall:
        return '24px 32px 0';
      case CenteredPageLayoutSizeEnum.Small:
        return '24px 64px 0';
      case CenteredPageLayoutSizeEnum.PDP:
        return '24px 0 0';
      case CenteredPageLayoutSizeEnum.Regular:
        return '24px 32px 0';
      case CenteredPageLayoutSizeEnum.FullScreen:
        return '0';
      case CenteredPageLayoutSizeEnum.DataExplorer:
        return '0';
      default:
        return '24px 64px 0';
    }
  }};
  display: flex;

  ${media.mobile} {
    margin: 0px;
  }

  ${media.tablet} {
    margin: 0px;
  }
`;

export enum CenteredPageLayoutSizeEnum {
  ExtraSmall = 'ExtraSmall',
  Small = 'Small',
  PDP = 'PDP',
  Regular = 'Regular',
  FullScreen = 'FullScreen',
  DataExplorer = 'DataExplorer',
}

type CenteredPageLayoutProps = {
  children: React.ReactNode,
  size?: CenteredPageLayoutSizeEnum,
  background?: ColorsType;
  dataCy?: string;
};

const CenteredPageLayout: React.FC<CenteredPageLayoutProps> = ({
  children,
  size = CenteredPageLayoutSizeEnum.Regular,
  background = Colors.Grey50,
  dataCy = 'centered-page-layout-wrapper',
}) => (
  <Container
    background={background}
    size={size}
    id="CenteredPageLayoutContainer"
    data-cy={dataCy}
  >
    <Content size={size} id="CenteredPageLayoutContent">
      <ContentPadding size={size} id="ContentPadding">
        {children}
      </ContentPadding>
    </Content>
  </Container>
);
export default CenteredPageLayout;
