import gql from 'graphql-tag';

/** Get all Cherre data to display on PDP Data Insights section! */

const CHERRE_DATA = gql`
query CherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId ($params: CherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyIdParams!){
  cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId (params: $params) {
    insights
    demographics
    priorYearDemographics
  }
}`;

export default CHERRE_DATA;
