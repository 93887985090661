import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import React from 'react';
import ScrollNavigation, { ScrollNavigationTypesEnum } from '../../elements/ScrollNavigation';
import IPDPSection from '../../models/interfaces/IPDPSection';
import getFilteredPDPSections from '../../utils/getFilteredPDPSections';

type PDPNavigationProps = {
  filteredPDPSections?: IPDPSection[];
  isActivePDP?: boolean;
  isPreviewPDP?: boolean;
  listing?: IListingGraphQL
}

const PDPNavigation: React.FC<PDPNavigationProps> = ({
  isActivePDP = false, isPreviewPDP = false, filteredPDPSections, listing,
}) => {
  const buttons: any = filteredPDPSections?.length
    ? filteredPDPSections?.map((section) => ({
      title: section.name,
      elementId: section.name,
    }))
    : getFilteredPDPSections(listing).map((section) => ({
      title: section.name,
      elementId: section.name,
    }));

  return (
    <ScrollNavigation
      type={ScrollNavigationTypesEnum.Inline}
      buttons={buttons}
      isActivePDP={isActivePDP}
      isPreviewPDP={isPreviewPDP}
    />
  );
};

export default PDPNavigation;
