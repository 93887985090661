import styled from '@emotion/styled';
import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingInfoFieldUtil from '@biproxi/models/utils/ListingInfoFieldUtil';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Divider, { DividerTypesEnum } from '../../elements/Divider';
import { useMobileMedia } from '../../utils/MediaQuery';

const Container = styled.div``;

const DetailsContainer = styled.div`
  display: flex;
  margin: 16px 0px 0px;
`;

const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const DetailItem = styled.div`
  display: flex;
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const DividerContainer = styled.div`
  display: flex;
  flex: 0.5;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
`;

type PDPDetailsProps = {
  id?: string;
  listing: IListingGraphQL;
  isModal?: boolean;
};

// TODO: fix the spacing on this component to reflect what is in Figma.
const PDPDetails: React.FC<PDPDetailsProps> = ({
  id,
  listing,
  isModal = false,
}) => {
  /** Hooks */
  const isMobile = useMobileMedia() || isModal;

  /**
   * This map shenanigans is to turn the special case
   * unit break downs in renderable objects and filter
   * out null fields
   */
  const arr = listing.info
    .filter((item) => Boolean(item.value))
    .map((item) => {
      if (typeof item.value === 'object') {
        return Object.keys(item.value).map((key) => ({
          fieldName: key,
          value: item.value[key],
        }));
      } return item;
    })
    .flat()
    /**
     * We need this extra filter at the end
     * to filter null fields after the
     * objects have been flattened
     */
    .filter((item) => Boolean(item.value));

  // the - 2 is to account for asset class and property type
  // not being in the array but displayed on the page in the same format
  const half = Math.ceil((arr.length - 2) / 2);
  const left = arr.slice(0, half);
  const right = arr.slice(half, arr.length);

  const TopDetails: React.FC = () => (
    <>
      {listing.assetClass && (
      <DetailItem>
        <Column>
          <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>Asset Class</Text>
        </Column>
        <Column>
          <Text type={TextTypesEnum.Bold14} color={Colors.Grey900}>{listing.assetClass}</Text>
        </Column>
      </DetailItem>
      )}
      {listing.propertyType && (
      <DetailItem>
        <Column>
          <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>Property Type</Text>
        </Column>
        <Column>
          <Text type={TextTypesEnum.Bold14} color={Colors.Grey900}>{listing.propertyType}</Text>
        </Column>
      </DetailItem>
      )}
    </>
  );

  return (
    <Container id={id}>
      <Text type={TextTypesEnum.Bold18} color={Colors.Black}>Details</Text>
      <DetailsContainer>
        {isMobile ? (
          <DetailsColumn>
            <TopDetails />
            {arr.map((item, index) => (
              <DetailItem key={index}>
                <Column>
                  <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>{item.fieldName}</Text>
                </Column>
                <Column>
                  <Text type={TextTypesEnum.Bold14} color={Colors.Grey900}>{ListingInfoFieldUtil.format(item.fieldName as any, item.value)}</Text>
                </Column>
              </DetailItem>
            ))}
          </DetailsColumn>
        ) : (
          <>
            <DetailsColumn>
              <TopDetails />
              {left.map((item, index) => (
                <DetailItem key={index}>
                  <Column>
                    <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>{item.fieldName}</Text>
                  </Column>
                  <Column>
                    <Text type={TextTypesEnum.Bold14} color={Colors.Grey900}>{ListingInfoFieldUtil.format(item.fieldName as any, item.value)}</Text>
                  </Column>
                </DetailItem>
              ))}
            </DetailsColumn>
            <DividerContainer>
              <Divider type={DividerTypesEnum.Vertical} />
            </DividerContainer>
            <DetailsColumn>
              {right.map((item, index) => (
                <DetailItem key={index}>
                  <Column>
                    <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>{item.fieldName}</Text>
                  </Column>
                  <Column>
                    <Text type={TextTypesEnum.Bold14} color={Colors.Grey900}>{ListingInfoFieldUtil.format(item.fieldName as any, item.value)}</Text>
                  </Column>
                </DetailItem>
              ))}
            </DetailsColumn>
          </>
        )}
      </DetailsContainer>
    </Container>
  );
};

export default PDPDetails;
