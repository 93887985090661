import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import AnalyticsEventTypesEnum from '@biproxi/models/enums/AnalyticsEventTypesEnum';
import FileAccessLevelsEnum from '@biproxi/models/enums/FileAccessLevelsEnum';
import { useMutation, useQuery } from '@apollo/client';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import * as ILeadService from '@biproxi/models/services/ILeadService';
import EventTrackerUtil from '@biproxi/models/utils/EventTrackerUtil';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import PDPVaultFolder from './PDPVaultFolder';
import Icon, { Icons } from '../../elements/Icon';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import useRequireAuthentication from '../../hooks/useRequireAuthentication.hook';
import { AppState, useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import useUser from '../../hooks/useUser.hook';
import { ListingSelectors } from '../../redux/listing.redux';
import { UserSelectors } from '../../redux/user.redux';
import GET_LEAD from '../../graphql/queries/lead.query';
import Loader, { LoaderSizes } from '../../elements/Loader';
import { ConfirmChangeModalTypesEnum } from '../modal/ConfirmChangeModal';
import REQUEST_LEAD_FILE_ACCESS_LEVEL from '../../graphql/mutations/requestLeadFileAccessLevel.mutation';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import useIsPrivateListing from '../../hooks/useIsPrivateListing.hook';

const Container = styled.div`
  position: relative;
`;

const Title = styled.div`
  margin-bottom: 16px;
`;

const Docs = styled.div`
  position: relative;
`;

const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LockedContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${media.mobile} {
    flex-direction: column;
  };
`;

const Locked = styled.div`
  background-color: ${Colors.Black75};
  height: 32px;
  border-radius: 8px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
`;

type PDPDocumentsProps = {
  id?: string;
  listing: IListingGraphQL;
};

const PDPDocuments: React.FC<PDPDocumentsProps> = ({
  id,
  listing,
}) => {
  /* Hooks */
  const isMobile = useMobileMedia();
  const { user } = useUser();
  const requireAuthentication = useRequireAuthentication();

  /* State */
  const totalFileCount = ListingUtil.vaultFiles(listing).length;
  const hasUserSignedCA = ListingUtil.hasUserSignedCA(listing, user?._id) || ListingUtil.isOwner(listing, user?._id);
  const { isPrivateListing, isPrivateListingAccess } = useIsPrivateListing({
    params: {
      user,
      listing,
    },
  });

  /* Actions */
  const dispatch = useAppDispatch();

  const popModal = () => dispatch(
    AppActions.popModal(),
  );

  const pushRequestLevel2AccessModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ConfirmChange,
      props: {
        type: ConfirmChangeModalTypesEnum.Success,
        title: 'You have requested access to the Level 2 Diligence Files',
        text: 'The broker must approve your request to gain access to these files. We’ll notify you when you are approved or denied.',
        confirm: (event: React.FormEvent) => {
          event?.preventDefault();
          popModal();
        },
      },
    }),
  );
  const pushUnlockDocumentsModal = () => dispatch(AppActions.pushModal({
    type: ModalTypesEnum.UnlockDocuments,
    props: {
      listing,
    },
  }));

  const requireAuthToUnlockDocuments = () => requireAuthentication(
    pushUnlockDocumentsModal,
    (store: AppState) => {
      const cachedListing = ListingSelectors.listing(store, listing._id);
      const cachedUser = UserSelectors.user(store);
      return !ListingUtil.hasUserSignedCA(cachedListing, cachedUser?._id);
    },
  );

  /* GraphQL */

  type FileAccessData = { lead?: ILeadGraphQL};

  type FileAccessParams = ILeadService.TRequestFileAccessLevelPayload;

  const [requestFileAccess] = useMutation<FileAccessData, FileAccessParams>(REQUEST_LEAD_FILE_ACCESS_LEVEL, {
    refetchQueries: [{
      query: GET_LEAD,
      variables: {
        params: {
          userId: user?._id,
          listingId: listing._id,
        },
      },
    }],
    awaitRefetchQueries: true,
  });

  type LeadData = { lead?: ILeadGraphQL };

  type LeadParams = ILeadService.TGetLeadPayload;

  const { data, loading } = useQuery<LeadData, LeadParams>(GET_LEAD, {
    variables: {
      params: {
        userId: user?._id,
        listingId: listing._id,
      },
    },
  });

  /* Effects */
  const lead = data?.lead ?? null;

  /* Render */
  const hasLevel2Access = ListingUtil.isOwner(listing, user?._id) || lead?.fileAccessLevel?.accessLevel === FileAccessLevelsEnum.Level2;
  const hasPrivateListingDocAccess = isPrivateListingAccess && hasUserSignedCA;

  if (loading) {
    return (
      <Container>
        <LoaderContainer>
          <Loader size={LoaderSizes.Large} color={Colors.Brand700 || Colors.Blurple700} />
        </LoaderContainer>
      </Container>
    );
  }

  if (isPrivateListing && !isPrivateListingAccess) {
    return (
      <Container id={id} onClick={() => (hasUserSignedCA ? null : requireAuthToUnlockDocuments())}>
        <Title>
          <Text type={TextTypesEnum.Bold18} color={Colors.Black}>
            Documents&nbsp;(
            {totalFileCount}
            )
          </Text>
          {hasUserSignedCA && (
          <Text type={TextTypesEnum.Regular16} color={Colors.Grey700}>
            Click on a folder to view documents
          </Text>
          )}
        </Title>
        <Docs>
          <LockedContainer>
            <Locked>
              <Icon icon={Icons.LockAltSolid} color={Colors.White} margin="0 8px 0 0" size={14} />
              <Text type={TextTypesEnum.Bold14} color={Colors.White}>Locked</Text>
            </Locked>
          </LockedContainer>
          {listing.vault.folders.map((folder, index) => (
            <PDPVaultFolder
              key={index}
              listing={listing}
              folder={folder}
              isActive={hasUserSignedCA}
              hasLevel2Access={hasLevel2Access}
            />
          ))}
        </Docs>
        <Button
          text={hasUserSignedCA ? 'Access requested' : 'Request access'}
          icon={Icons.UnlockAltSolid}
          disabled={hasUserSignedCA}
          iconSize={18}
          iconColor={hasUserSignedCA ? Colors.Black : Colors.White}
          type={ButtonTypesEnum.Primary}
          size={ButtonSizesEnum.Large}
          margin="32px 0 0"
        />
      </Container>
    );
  }

  return (
    <Container id={id} onClick={() => (hasUserSignedCA ? null : requireAuthToUnlockDocuments())}>
      <Title>
        <Text type={TextTypesEnum.Bold18} color={Colors.Black}>
          Documents&nbsp;(
          {totalFileCount}
          )
        </Text>
        {hasUserSignedCA && (
          <Text type={TextTypesEnum.Regular16} color={Colors.Grey700}>
            Click on a folder to view documents
          </Text>
        )}
      </Title>
      <Docs>
        {!hasUserSignedCA && (
          <LockedContainer>
            <Locked>
              <Icon icon={Icons.LockAltSolid} color={Colors.White} margin="0 8px 0 0" size={14} />
              <Text type={TextTypesEnum.Bold14} color={Colors.White}>Locked</Text>
            </Locked>
          </LockedContainer>
        )}
        {listing.vault.folders.map((folder, index) => (
          <PDPVaultFolder
            key={index}
            listing={listing}
            folder={folder}
            isActive={hasUserSignedCA}
            hasLevel2Access={hasLevel2Access}
          />
        ))}
      </Docs>
      {hasUserSignedCA && (
      <ButtonContainer>
        {((listing.vault.zipFileId) || (hasLevel2Access && listing.vault.zipFileLevel2Id) || hasPrivateListingDocAccess) && (
          <Button
            text={(hasLevel2Access || !listing.vault.zipFileLevel2Id) ? 'Download all documents' : 'Download Level 1 Documents'}
            type={ButtonTypesEnum.Primary}
            size={ButtonSizesEnum.Large}
            onClick={() => {
              if (hasLevel2Access && listing.vault.zipFileLevel2) {
                window.open(listing.vault.zipFileLevel2?.url);
              } else {
                window.open(listing.vault.zipFile?.url);
              }

              if (ListingUtil.isOwner(listing, user._id)) return;

              EventTrackerUtil.trackWindowEvent(
                AnalyticsEventTypesEnum.DownloadedDueDiligenceVault,
                window,
                {
                  userId: user?._id,
                  organizationId: user?.organization?._id,
                  listingId: listing._id,
                  listingUserId: listing?.meta?.createdBy,
                  listingOrganizationId: listing?.organizationId,
                },
              );
            }}
            margin="32px 0 0 0"
          />
        )}
        {(!hasLevel2Access && ListingUtil.containsLevel2(listing)
        && lead?.fileAccessLevel?.requestedAccessLevel !== FileAccessLevelsEnum.Level2)
        && (
        <Button
          text="Unlock Level 2 Access"
          icon={Icons.UnlockAltSolid}
          iconSize={18}
          type={ButtonTypesEnum.Primary}
          size={ButtonSizesEnum.Large}
          margin={isMobile ? '16px 0' : '32px 0 0 8px'}
          onClick={() => {
            requestFileAccess({
              variables: {
                params: {
                  accessLevel: FileAccessLevelsEnum.Level2,
                  leadId: lead._id,
                },
              },
            });
            pushRequestLevel2AccessModal();
          }}
        />
        )}
      </ButtonContainer>
      )}
      {!hasUserSignedCA && (
        <Button
          text="Unlock documents"
          icon={Icons.UnlockAltSolid}
          iconSize={18}
          type={ButtonTypesEnum.Primary}
          size={ButtonSizesEnum.Large}
          margin="32px 0 0"
          data-cy="pdp-unlock-docs-button"
        />
      )}
    </Container>
  );
};

export default PDPDocuments;
