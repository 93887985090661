import styled from '@emotion/styled';
import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import * as ILeadService from '@biproxi/models/services/ILeadService';
import StringUtil from '@biproxi/models/utils/StringUtil';
import { IUserGraphQL } from '@biproxi/models/interfaces/IUser';
import { useQuery } from '@apollo/client';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import TimeUtil from '@biproxi/models/utils/TimeUtil';
import { ILeadGraphQL } from '@biproxi/models/interfaces/ILead';
import BrokerCommisionEnum from '@biproxi/models/enums/BrokerCommisionEnum';
import { useNavigate } from 'react-router-dom';
import BoxShadows from '../../styles/BoxShadows';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import GenericCard from '../../elements/GenericCard';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import { useMobileMedia, useTabletMedia } from '../../utils/MediaQuery';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import Auth from '../../utils/Auth';
import useRequireAuthentication from '../../hooks/useRequireAuthentication.hook';
import { CustomSpacer } from '../../elements/Flex';
import GET_USER_WITH_OFFERS from '../../graphql/queries/userWithOffers.query';
import { ListingActions, ListingSelectors } from '../../redux/listing.redux';
import { ContactBrokerModalConfig } from '../modal/ContactBrokerModal';
import useUser from '../../hooks/useUser.hook';
import GET_LEAD_BY_LISTING_AND_USER_ID from '../../graphql/queries/leadByListingAndUserId.query';
import { RequestPrivateListingAccessModalConfig } from '../modal/RequestPrivateListingAccessModal';
import useHandleRouteChange from '../../hooks/useHandleRouteChange.hook';

const Container = styled.div`
  position: sticky;
  top: 152px;
  margin-left: 48px;
`;

const InnerContainer = styled.div<{margin: string}>`
  padding: 16px;
  margin: ${(props) => props.margin};
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border-radius: 4px;
  border: 1px solid ${Colors.Grey300};
  row-gap: 4px;
`;

const RowItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GreyBox = styled.div`
  background: ${Colors.Grey50};
  border-radius: 4px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
`;

type PDPPricingProps = {
  listing: IListingGraphQL;
  isModal?: boolean;
};

// There is probably a better way to do this.
// TODO: revist later when there is more time.

const PDPPricing: React.FC<PDPPricingProps> = ({
  listing,
  isModal = false,
}) => {
  /** State */
  const [isBroker, setIsBroker] = React.useState(false);
  const [curOffer, setCurOffer] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  const [privateListingButtonLoading, setPrivateListingButtonLoading] = React.useState(null);
  const [time, setTime] = React.useState(TimeUtil.now());
  const showPreview = useAppSelector(ListingSelectors.showPreview);

  /** Hooks */
  const isMobile = useMobileMedia() || isModal;
  const isTablet = useTabletMedia();
  const requireAuthentication = useRequireAuthentication();
  const { user } = useUser();
  const handleRouteChange = useHandleRouteChange();
  const navigate = useNavigate();

  /** Actions */
  const dispatch = useAppDispatch();
  const togglePreviewCreateListing = () => {
    navigate(ListingUtil.createListingPath(listing._id));
    dispatch(ListingActions.setShowPreview({ showPreview: !showPreview }));
  };

  const pushContactBrokerModal = (props: ContactBrokerModalConfig) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.ContactBroker,
      props,
    }),
  );

  const pushOfferModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.Offer,
      props: {
        listing: curOffer ? null : listing,
        offer: curOffer,
      },
    }),
  );

  const pushLoginModal = () => {
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.Login,
        props: {},
      }),
    );
  };

  const pushRequestPrivateListingAccessModal = (props: RequestPrivateListingAccessModalConfig) => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.RequestPrivateListingAccessModal,
      props,
    }),
  );

  /** Graphql */
  /**
   * If the user is authenticated, hide the contact
   * broker button if the current user is the owner
   * of the listing. Need the offer so we can revise it.
   */
  const { data } = useQuery<{ user: IUserGraphQL }>(GET_USER_WITH_OFFERS, {
    skip: !Auth.isAuthenticated(),
  });

  type LeadData = { getLeadByListingAndUserId?: ILeadGraphQL };

  type LeadParams = ILeadService.TGetLeadPayload;

  const { data: leadData, loading: isLeadDataLoading, refetch: refetchLeadData } = useQuery<LeadData, LeadParams>(GET_LEAD_BY_LISTING_AND_USER_ID, {
    skip: !user,
    variables: {
      params: {
        userId: user?._id,
        listingId: listing._id,
      },
    },
  });

  /* Effects */
  const lead = leadData?.getLeadByListingAndUserId ?? null;

  /** Effects */
  React.useEffect(() => {
    /**
     * Re-render every second to show real time countdown
     * if call for offers is set.
     */
    let interval;
    if (g?.callForOffersAt > TimeUtil.now()) {
      interval = setInterval(() => setTime(TimeUtil.now()), 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    if (data?.user) {
      if (ListingUtil.isOwner(listing, data?.user?._id)) {
        setIsBroker(true);
      } else {
        const offer = data.user.offers?.find((offer) => offer.listingId === listing._id);
        if (offer) {
          setCurOffer(offer);
        }
      }
      setLoaded(true);
    }
  }, [data]);

  /** Render */
  const g = listing.guidance;
  const showGuidance = g.dueDiligencePeriod || g.closingPeriod || g.depositAmount || g.brokerCommisionAmount > 0;
  const isCreateListing = window?.location?.href?.includes('/create-listing') || false;

  const PricingDetails: React.FC = () => (
    <>
      {g.askingPrice && (
        <>
          <Text type={TextTypesEnum.Regular16} color={Colors.Grey700}>
            Asking Price
          </Text>
          <Text type={TextTypesEnum.Bold24} color={Colors.Black} margin="0 0 16px">
            {`$${StringUtil.formatNumber(g?.askingPrice?.toString())}`}
          </Text>
        </>
      )}
      {g.callForOffersAt > time && (
      <>
        <Text type={TextTypesEnum.Regular16} color={Colors.Grey700}>
          Call for offers
        </Text>
        <Text type={TextTypesEnum.Bold24} color={Colors.Black}>
          {`${TimeUtil.format(g.callForOffersAt, 'MMM d \'at\' h:mma', listing.address.timeZoneId)}`}
        </Text>
        <Text type={TextTypesEnum.Regular14} color={Colors.Red500} margin="0 0 16px">
          {`Ends in ${TimeUtil.formattedCountDown(time, g?.callForOffersAt)}`}
        </Text>
      </>
      )}
      {showGuidance && (
      <InnerContainer margin={isBroker ? '0' : '0 0 24px'}>
        <RowItem>
          <Text type={TextTypesEnum.Bold16} color={Colors.Grey900}>
            Seller guidance
          </Text>
        </RowItem>
        {g.dueDiligencePeriod && (
        <RowItem>
          <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
            Diligence period
          </Text>
          <Text type={TextTypesEnum.Bold14} color={Colors.Grey900}>
            {`${g.dueDiligencePeriod} days`}
          </Text>
        </RowItem>
        )}
        {g.closingPeriod && (
        <RowItem>
          <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
            Closing period
          </Text>
          <Text type={TextTypesEnum.Bold14} color={Colors.Grey900}>
            {`${g.closingPeriod} days`}
          </Text>
        </RowItem>
        )}
        {g.dueDiligencePeriod && g.closingPeriod && (
        <RowItem>
          <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
            Total escrow period
          </Text>
          <Text type={TextTypesEnum.Bold14} color={Colors.Grey900}>
            {`${g.dueDiligencePeriod + g.closingPeriod} days`}
          </Text>
        </RowItem>
        )}
        {g.depositAmount && (
        <RowItem>
          <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
            Deposit
          </Text>
          <Text type={TextTypesEnum.Bold14} color={Colors.Grey900}>
            {`$${StringUtil.formatNumber(g.depositAmount)}`}
          </Text>
        </RowItem>
        )}
        {(g.brokerCommision === BrokerCommisionEnum.Yes || g.brokerCommision === BrokerCommisionEnum.CallBroker) && (
        <RowItem>
          <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
            Buyer&apos;s broker commission
          </Text>
          <Text type={TextTypesEnum.Bold14} color={Colors.Grey900}>
            {g.brokerCommision}
          </Text>
        </RowItem>
        )}
        {(g.brokerCommisionAmount > 0 && (g.brokerCommision === BrokerCommisionEnum.Yes)) && (
        <RowItem>
          <Text type={TextTypesEnum.Medium14} color={Colors.Grey500}>
            Commission amount
          </Text>
          <Text type={TextTypesEnum.Bold14} color={Colors.Grey900}>
            {`${StringUtil.formatNumber(g.brokerCommisionAmount.toFixed(2))}%`}
          </Text>
        </RowItem>
        )}
      </InnerContainer>
      )}
      {loaded && (
      <>
        {isBroker ? (
          <>
            {showGuidance && <CustomSpacer height="24px" />}
            <GreyBox>
              <Text type={TextTypesEnum.Regular16} color={Colors.Grey900}>
                This is your listing
              </Text>
              {isCreateListing ? (
                <Button
                  text="Edit listing"
                  onClick={() => togglePreviewCreateListing()}
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Medium}
                />
              ) : (
                <Button
                  text="Edit listing"
                  onClick={() => {
                    handleRouteChange('/app/create-listing', { listingId: listing._id });
                  }}
                  type={ButtonTypesEnum.Primary}
                  size={ButtonSizesEnum.Medium}
                />
              )}
            </GreyBox>
          </>
        ) : (
          <>
            {
                listing?.isPrivateListing && !lead?.privateListingAccess?.accessApproved ? (
                  <Button
                    text={lead?.privateListingAccess?.accessRequested && !lead?.privateListingAccess?.accessApproved ? 'Access requested' : 'Request permission'}
                    onClick={() => requireAuthentication(() => {
                      if (!isBroker) {
                        pushRequestPrivateListingAccessModal({
                          params: {
                            listing,
                            lead,
                          },
                          onSubmit: async () => {
                            setPrivateListingButtonLoading(true);
                          },
                          onComplete: () => {
                            setPrivateListingButtonLoading(false);
                            refetchLeadData();
                          },
                        });
                      }
                    })}
                    type={ButtonTypesEnum.Primary}
                    size={ButtonSizesEnum.Large}
                    disabled={lead?.privateListingAccess?.accessRequested && !lead?.privateListingAccess?.accessApproved}
                    margin="8px 0px 0px 0px"
                    isLoading={privateListingButtonLoading || isLeadDataLoading}
                    isFullWidth
                  />
                ) : (
                  <>
                    <Button
                      text="Contact broker"
                      onClick={() => requireAuthentication(() => {
                        if (!isBroker) {
                          pushContactBrokerModal({
                            params: {
                              listingIdProp: listing._id,
                            },
                          });
                        }
                      })}
                      type={ButtonTypesEnum.Outline}
                      size={ButtonSizesEnum.Large}
                      isFullWidth
                      data-cy="pdp-pricing-contact-broker-button"
                    />
                    <Button
                      text={curOffer ? 'Revise offer' : 'Make an offer'}
                      onClick={() => requireAuthentication(() => {
                        if (!isBroker) {
                          pushOfferModal();
                        }
                      })}
                      type={ButtonTypesEnum.Primary}
                      size={ButtonSizesEnum.Large}
                      margin="8px 0px 0px 0px"
                      isFullWidth
                      data-cy="pdp-pricing-make-or-revise-offer-button"
                    />
                  </>
                )
              }
          </>
        )}
      </>
      )}
      {!data && (
        <Button
          text={listing?.isPrivateListing ? 'Sign in to request permission' : 'Sign in to make offer'}
          type={ButtonTypesEnum.Primary}
          size={ButtonSizesEnum.Large}
          onClick={() => pushLoginModal()}
          isFullWidth
        />
      )}
    </>
  );

  if (isMobile || isTablet) {
    return (
      <PricingDetails />
    );
  }

  return (
    <Container>
      <GenericCard width="352px" boxShadow={BoxShadows.Standard}>
        <PricingDetails />
      </GenericCard>
    </Container>
  );
};

export default PDPPricing;
