import React from 'react';
import { GetServerSideProps } from 'next';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import styled from '@emotion/styled';
import AnalyticsEventTypesEnum from '@biproxi/models/enums/AnalyticsEventTypesEnum';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import striptags from 'striptags';
import { decode } from 'html-entities';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import UserUtil from '@biproxi/models/utils/UserUtil';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import { AppState, useAppSelector } from '../../../redux/store';
import PDPHeader from '../../../components/pdp/PDPHeader';
import NextUtil from '../../../utils/NextUtil';
import IPageConfiguration from '../../../models/interfaces/IPageConfiguration';
import GET_LISTING from '../../../graphql/queries/listing.query';
import { initializeApollo } from '../../../graphql/client';
import CenteredPageLayout, {
  CenteredPageLayoutSizeEnum,
} from '../../../components/CenteredPageLayout';
import Colors from '../../../styles/Colors';
import PDPPricing from '../../../components/pdp/PDPPricing';
import Divider from '../../../elements/Divider';
import PDPImages from '../../../components/pdp/PDPImages';
import PDPNavigation from '../../../components/pdp/PDPNavigation';
import getFilteredPDPSections from '../../../utils/getFilteredPDPSections';
import useListing from '../../../hooks/useListing.hook';
import {
  media, useMobileMedia, useTabletMedia,
} from '../../../utils/MediaQuery';
import PageError from '../../../components/PageError';
import Icons from '../../../elements/Icons';
import useUserHook from '../../../hooks/useUser.hook';
import NextPageContainer from '../../../containers/NextPage.container';
import PDPDataExplorer from '../../../components/pdp/PDPDataExplorer';
import Auth from '../../../utils/Auth';
import Loader, { LoaderSizes } from '../../../elements/Loader';
import PDPSectionsEnum from '../../../models/enums/PDPSectionsEnum';
import useIsPrivateListing from '../../../hooks/useIsPrivateListing.hook';
import IPDPSection from '../../../models/interfaces/IPDPSection';
// import PrivateListingPDPImage from '../../../../public/private-listing-pdp.png';
import SuperAdminPDPTools from '../../../components/pdp/SuperAdminPDPTools';
import * as UrlUtil from '../../../utils/UrlUtil';

type ListingPageProps = {
  config: IPageConfiguration;
  listing: IListingGraphQL;
};

const PageContainer = styled.div`
  display: flex;
  width: fill-available;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const InnerContent = styled.div`
  display: flex;
`;

const ScrollableContent = styled.div`

  ${media.desktop} {
    max-width: 840px;
    width: fill-available;
  }

  ${media.mobile} {
    width: 100%;
    padding: 16px;
  }

  ${media.tablet} {
    width: 100%;
    padding: 16px;
  }
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavContainer = styled.div``;

const Spacer = styled.div`
  height: 32px;
`;

const ListingPage: React.FC<ListingPageProps> = ({ config, listing }) => {
  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */

  /** Contentful - Selectors */
  const {
    contentful: {
      brandCollection: {
        companyMediaAssets,
      },
    },
  } = useAppSelector((state: AppState) => state);

  const lockedListingImage:string = companyMediaAssets.lockedListingImage.url || companyMediaAssets.lockedListingimage;

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */
  /* State */
  const listingIdFromURL = UrlUtil.parse(window?.location?.toString())?.pathname?.split('/')?.[2]; // a little hacky
  const { user } = useUserHook();
  const { listing: cacheListing } = useListing(listingIdFromURL ?? null);
  const [sentAnalyticEvent, setSentAnalyticEvent] = React.useState<boolean>(false);

  listing = cacheListing || listing;

  const [isDOMLoaded, setIsDOMLoaded] = React.useState<boolean>(false);
  const [listingLoading, setListingLoading] = React.useState<boolean>(true);
  const [listingId, setListingId] = React.useState<string>(listingIdFromURL as string);
  const { isPrivateListing, isPrivateListingAccess } = useIsPrivateListing({
    params: {
      user,
      listing,
    },
  });
  const nextConfig: any = config;

  /** Hooks */
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  /** Effects */
  React.useEffect(() => {
    /**
     * If listing is null here it means
     * that the listingId that was specified
     * in the url is invalid and analytics should
     * not be tracked
     */
    if (listing && !sentAnalyticEvent) {
      window.analytics.track(
        AnalyticsEventTypesEnum.ViewedListing,
        {
          userId: user?._id ?? null,
          organizationId: user?.organization?._id ?? null,
          listingId: listing._id,
          listingUserId: listing.user._id,
          listingOrganizationId: listing.organizationId,
        },
      );
      setSentAnalyticEvent(true);
    }
  }, [listing]);

  React.useEffect(() => {
    setIsDOMLoaded(true);
  }, []);

  /* Render */
  if (!listing) {
    setTimeout(() => {
      setListingLoading(false);
    }, 6000);

    if (NextUtil.hasWindow() && !listingId) {
      const path = window.location.pathname;
      const pathComponents = path.split('/');
      setListingId(pathComponents[2]);
    }

    return (
      <CenteredPageLayout
        size={CenteredPageLayoutSizeEnum.Small}
        background={Colors.White}
      >
        {listingLoading ? (
          <LoaderContainer>
            <Loader color={Colors.Brand700 || Colors.Blurple700} size={LoaderSizes.Large} />
          </LoaderContainer>
        ) : (
          <PageError
            icon={Icons.BuildingSolid}
            header="The listing was not found"
            body="We're sorry, but the listing you are looking for does not appear to exist"
            warning={false}
          />
        )}
      </CenteredPageLayout>
    );
  }

  const sections = getFilteredPDPSections(listing);
  let filteredSections: IPDPSection[] = sections;

  /** Strip HTMl entities from listing description */
  const tagFreeDescription = striptags(listing.description);
  const decodedDescription = decode(tagFreeDescription);

  /** Establish search engine schema */
  const schemaData = {
    '@context': 'http://schema.org/',
    '@graph':
    [
      {
        '@type': 'RealEstateListing',
        name: ListingUtil.name(listing),
        author: UserUtil.fullName(listing.user),
        description: decodedDescription,
        image: `${listing?.media?.files[0]?.url}` ?? 'None',
        about: ListingUtil.isValidPortfolioListing(listing) ? 'Multiple Addresses' : AddressUtil.formatAddress(listing.address),
      },
      {
        '@type': 'Offer',
        priceCurrency: 'USD',
        price: `${listing.guidance?.askingPrice ?? 'Not Specified'}`,
      },
    ],
  };

  const filterPrivateListingSections = (pdpSections) => {
    const hiddenSections: string[] = [PDPSectionsEnum.PortfolioAddresses, PDPSectionsEnum.Description, PDPSectionsEnum.Highlights, PDPSectionsEnum.Highlights, PDPSectionsEnum.Valuation, PDPSectionsEnum.Location, PDPSectionsEnum.DataInsights];
    return pdpSections.filter((section) => !hiddenSections.includes(section.name));
  };

  if (isPrivateListing && !isPrivateListingAccess) {
    filteredSections = filterPrivateListingSections(sections);
  }

  if (isPrivateListing && !isPrivateListingAccess) {
    return (
      <>
        <Head>
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
          />
        </Head>
        <NextSeo
          title="Private Listing"
          openGraph={{
            type: 'website',
            title: `${listing.address.city}, ${listing.address.state} ${listing.address.zip}`,
            description: `${decodedDescription}`,
            url: `https://${nextConfig?.pageContext?.hostName ?? window.location.hostname}${nextConfig?.pageContext?.resolvedUrl ?? window.location.pathname}`,
            images: [
              {
                url: `${lockedListingImage}` ?? 'None',
                width: 600,
                height: 400,
                alt: 'Listing Image',
                type: 'image/jpeg',
              },
            ],
            site_name: 'Biproxi.com',
          }}
        />
        <NextPageContainer>
          <CenteredPageLayout
            size={CenteredPageLayoutSizeEnum.PDP}
            background={Colors.White}
          >
            <PageContainer>
              <ContentContainer data-cy="content-container">
                {isMobile || isTablet ? (
                  <PDPNavigation listing={listing} />
                ) : (
                  <PDPHeader listing={listing} isActivePDP />
                )}
                <PDPImages listing={listing} />
                <InnerContent data-cy="inner-content-container">
                  <ScrollableContent>
                    {isMobile || isTablet ? (
                      <>
                        <PDPHeader listing={listing} />
                        <Divider margin="24px 0px 0px 0px" />
                      </>
                    ) : (
                      <PDPNavigation listing={listing} filteredPDPSections={filteredSections} isActivePDP />
                    )}
                    <Spacer />
                    {isDOMLoaded && filteredSections.map((section, index) => (
                      <React.Fragment key={index}>
                        <>
                          {section.component}
                          {index !== sections.length - 1 && (
                          <Divider margin="24px 0px 32px 0px" />
                          )}
                        </>
                      </React.Fragment>
                    ))}
                    {user?.isSuperUser && (
                      <SuperAdminPDPTools listing={listing} />
                    )}
                  </ScrollableContent>
                  {(!isMobile && !isTablet) && (
                  <NavContainer>
                    <PDPPricing listing={listing} />
                  </NavContainer>
                  )}
                </InnerContent>
              </ContentContainer>
            </PageContainer>
          </CenteredPageLayout>
        </NextPageContainer>
      </>
    );
  }

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      </Head>
      <NextSeo
        title={ListingUtil.name(listing)}
        openGraph={{
          type: 'website',
          title: `${listing.address.address1}, ${listing.address.city}, ${listing.address.state} ${listing.address.zip}`,
          description: `${decodedDescription}`,
          images: [
            {
              url: `${listing?.media?.files[0]?.url}` ?? 'None',
              width: 600,
              height: 400,
              alt: 'Listing Image',
              type: 'image/jpeg',
            },
          ],
          site_name: 'Biproxi.com',
        }}
      />
      <NextPageContainer>
        <CenteredPageLayout
          size={CenteredPageLayoutSizeEnum.PDP}
          background={Colors.White}
        >
          <PageContainer>
            <ContentContainer datay-cy="content-container">
              {isMobile || isTablet ? (
                <PDPNavigation listing={listing} />
              ) : (
                <PDPHeader listing={listing} isActivePDP />
              )}
              {isMobile && <PDPDataExplorer listing={listing} isUserLoggedIn={Auth.isAuthenticated()} />}
              <PDPImages listing={listing} />
              {!isMobile && <PDPDataExplorer listing={listing} isUserLoggedIn={Auth.isAuthenticated()} />}
              <InnerContent data-cy="inner-content-container">
                <ScrollableContent>
                  {isMobile || isTablet ? (
                    <>
                      <PDPHeader listing={listing} />
                      <Divider margin="24px 0px 0px 0px" />
                    </>
                  ) : (
                    <PDPNavigation listing={listing} isActivePDP />
                  )}
                  <Spacer />
                  {sections.map((section, index) => (
                    <React.Fragment key={index}>
                      <>
                        {section.component}
                        {index !== sections.length - 1 && (
                          <Divider margin="24px 0px 32px 0px" />
                        )}
                      </>
                    </React.Fragment>
                  ))}
                  {user?.isSuperUser && (
                    <SuperAdminPDPTools listing={listing} />
                  )}
                </ScrollableContent>
                {(!isMobile && !isTablet) && (
                <NavContainer>
                  <PDPPricing listing={listing} />
                </NavContainer>
                )}
              </InnerContent>
            </ContentContainer>
          </PageContainer>
        </CenteredPageLayout>
      </NextPageContainer>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<ListingPageProps> = async (
  context,
) => {
  try {
    const config = await NextUtil.getPageConfiguration(context);
    const { query } = context;
    const client = initializeApollo(context);

    const { data } = await client.query({
      query: GET_LISTING,
      variables: {
        listingId: query.listingId,
      },
    });

    config.listing = data?.listing ?? null;

    return {
      props: {
        config,
        listing: data.listing,
      },
    };
  } catch (e) {
    return {
      props: {
        config: {
          user: null,
          listing: null,
          lead: null,
          privateEvent: null,
          initialApolloState: null,
          initialReduxState: null,
          pageContext: null,
        },
        listing: null,
      },
    };
  }
};

export default ListingPage;
