import styled from '@emotion/styled';
import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import Colors from '../../styles/Colors';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Text, { TextTypesEnum } from '../../elements/Text';

const Container = styled.div``;

const DescriptionContainer = styled.div`
  line-height: 32px;
  margin: 0 0 32px 0;
`;

const HTMLText = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "aktiv-grotesk";
  line-height: 28px; // not a multiple of 8 but this is what we want here

  > p {
    padding: 8px 0 0;
  }
`;

type PDPDescriptionProps = {
  id?: string;
  listing: IListingGraphQL;
};

const PDPDescription: React.FC<PDPDescriptionProps> = ({
  id,
  listing,
}) => {
  const [showFullDescription, setShowFullDescription] = React.useState<boolean>(false);

  return (
    <Container id={id}>
      <Text
        type={TextTypesEnum.Bold18}
        color={Colors.Black}
        margin="0px 0px 16px 0px"
      >
        Description
      </Text>
      <DescriptionContainer>
        <HTMLText dangerouslySetInnerHTML={{ __html: showFullDescription || listing?.description?.length < 400 ? listing.description : `${listing.description.substring(0, 400)}...` }} />
      </DescriptionContainer>
      {listing?.description?.length > 400 && (
        <Button
          type={ButtonTypesEnum.Outline}
          size={ButtonSizesEnum.Large}
          text={
              showFullDescription
                ? 'Hide full description'
                : 'Show full description'
            }
          onClick={() => setShowFullDescription(!showFullDescription)}
        />
      )}
    </Container>
  );
};

export default PDPDescription;
