import styled from '@emotion/styled';
import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import StringUtil from '@biproxi/models/utils/StringUtil';
import IListingContact from '@biproxi/models/interfaces/IListingContact';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import ListingContactBox from '../ListingContactBox';
import Flex from '../../elements/Flex';
import Icon, { Icons } from '../../elements/Icon';
import { media, useMobileMedia } from '../../utils/MediaQuery';
import Divider from '../../elements/Divider';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { ModalTypesEnum } from '../modal/Modal';
import LinkText from '../../elements/LinkText';
import useRequireAuthentication from '../../hooks/useRequireAuthentication.hook';

const Container = styled.div``;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 32px;
  margin: 0px 0px 16px 0px;

  ${media.mobile} {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }
`;

type PDPContactsProps = {
  id?: string;
  listing: IListingGraphQL;
};

const PDPContacts: React.FC<PDPContactsProps> = ({
  id,
  listing,
}) => {
  /** Hooks */
  const isMobile = useMobileMedia();
  const requireAuthentication = useRequireAuthentication();

  /** Render */
  const defaultContact: IListingContact = {
    _id: 'x',
    name: `${listing.user.firstName} ${listing.user.lastName}`,
    email: listing.user.email,
    phoneNumber: listing.user?.displayedPhoneNumber ?? listing.user.phoneNumber,
    profileImageUrl: listing.user.profileImageFile?.url ?? '',
  };

  const dispatch = useAppDispatch();
  const pushBrokerListingsModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.BrokerListings,
      props: {
        brokerUserId: listing?.user?._id,
      },
    }),
  );

  return (
    <Container id={id}>
      <Text
        type={TextTypesEnum.Bold18}
        color={Colors.Black}
        margin="0px 0px 24px 0px"
      >
        Contacts
      </Text>
      <ContactGrid>
        {listing?.contacts?.map((contact, index) => (
          <React.Fragment key={index}>
            <ListingContactBox
              contact={contact}
              isPdpPage
            />
            {isMobile && index !== listing?.contacts?.length - 1 && (
            <Divider />
            )}
          </React.Fragment>
        ))}
        {/* Backfill code: display default contact if no contacts present */}
        {!listing?.contacts?.length && (
          <ListingContactBox
            contact={defaultContact}
            isPdpPage
          />
        )}
      </ContactGrid>
      {listing.listedByOwner ? (
        <Text
          type={TextTypesEnum.Regular16}
          color={Colors.Black}
          margin="32px 0px 0px 0px"
        >
          This property is listed by the owner.
        </Text>
      ) : (
        <Flex margin="32px 0px 0px 0px">
          <Icon
            icon={Icons.FileCertificateSolid}
            color={Colors.Black}
            margin="0px 8px 0px 0px"
          />
          <Text
            type={TextTypesEnum.Regular16}
            color={Colors.Black}
          >
            License held by
            {' '}
            {StringUtil.formatLicense(listing?.license)}
          </Text>
        </Flex>
      )}
      <LinkText
        margin="16px 0px 0px"
        type={TextTypesEnum.Regular16}
        onClick={() => requireAuthentication(() => {
          pushBrokerListingsModal();
        })}
      >
        See all listings from this user.
      </LinkText>
    </Container>
  );
};

export default PDPContacts;
