import ListingInfoFieldLabels from '../objects/ListingInfoFieldLabels.object';
import TListingInfoFieldTypes from '../types/TListingInfoFieldTypes';
import ListingInfoFieldNamesEnum from '../enums/ListingInfoFieldNamesEnum';
import StringUtil from './StringUtil';
import TimeUtil from './TimeUtil';

const formatters = {
  [ListingInfoFieldNamesEnum.YearBuilt]: (value: number) => TimeUtil.format(value, 'yyyy'), // Date
  [ListingInfoFieldNamesEnum.YearRenovated]: (value: number) => TimeUtil.format(value, 'yyyy'), // Date
  [ListingInfoFieldNamesEnum.BuildingSqFt]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.LotSize]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.NumberofUnitsSpaces]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.Occupancy]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.Anchored]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.ClearHeight]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.GradeLevelDoors]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.LoadingDocks]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.ADR]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.EconomicScale]: (value) => value, // Enum
  [ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily]: (value) => value, // Special
  [ListingInfoFieldNamesEnum.ParkingSpaces]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.NumberofUnits]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.UnitPriceFor10X10]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.NumberOfFloors]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.BoatRVStorage]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.NumberofRooms]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.AgeRestriction]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.UnitBreakdownForSelfStorage]: (value: string) => value, // Special
  [ListingInfoFieldNamesEnum.TenantName]: (value: string) => value,
  [ListingInfoFieldNamesEnum.Credit]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.Guarantor]: (value: string) => value,
  [ListingInfoFieldNamesEnum.AnnualRent]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.Ownership]: (value) => value, // Enum
  [ListingInfoFieldNamesEnum.LeaseExpiration]: (value: number) => TimeUtil.format(value, 'DD'), // Date
  [ListingInfoFieldNamesEnum.NumberofLeaseRenewals]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.YearsPerRenewalOption]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.BuildingClass]: (value) => value, // Enum
  [ListingInfoFieldNamesEnum.NumberofTenants]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.OnCampus]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.WeightedAverageLeaseTerm]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.RevPAR]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.CreditRatingAgency]: (value) => value, // Enum
  [ListingInfoFieldNamesEnum.CreditRating]: (value: string) => value,
  [ListingInfoFieldNamesEnum.LeaseCommencementDate]: (value: number) => TimeUtil.format(value, 'DD'), // Date
  [ListingInfoFieldNamesEnum.RemainingTotalTerm]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.KickOutClause]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.AnnualRentIncrease]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.DevelopableLand]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.Zoning]: (value: string) => value,
  [ListingInfoFieldNamesEnum.SIC]: (value: number) => value,
  [ListingInfoFieldNamesEnum.NAICS]: (value: number) => value,
  [ListingInfoFieldNamesEnum.ConservationEasement]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.EnvironmentalSurvey]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.LocationType]: (value) => value, // Enum
  [ListingInfoFieldNamesEnum.DefaultStatus]: (value) => value, // Enum
  [ListingInfoFieldNamesEnum.DefaultInterest]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.AccruedInterestAmount]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.InterestRate]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.LeinPosition]: (value) => value, // Enum
  [ListingInfoFieldNamesEnum.AssetClass]: (value: string) => value,
  [ListingInfoFieldNamesEnum.UPB]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.AmortizationRate]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.MaturityDate]: (value: number) => TimeUtil.format(value, 'DD'), // Date
  [ListingInfoFieldNamesEnum.NumberofPads]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.Type]: (value) => value, // Enum
  [ListingInfoFieldNamesEnum.NumberofSpaces]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.NumberofLevels]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.OperatingForChildcare]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.Tenancy]: (value) => value, // Enum
  [ListingInfoFieldNamesEnum.CeilingHeight]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.LabToOfficeSqFtRatio]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.TotalSqFt]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.FeeSimple]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.CommunityType]: (value) => value, // Enum
  [ListingInfoFieldNamesEnum.AverageRentPerPad]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.WaterPaidbyPark]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.ElectricBilledDirect]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.UnitBreakdownForMobileHome]: (value) => value,
  [ListingInfoFieldNamesEnum.Singlewide]: (value) => value,
  [ListingInfoFieldNamesEnum.Doublewide]: (value) => value,
  [ListingInfoFieldNamesEnum.Triplewide]: (value) => value,
  [ListingInfoFieldNamesEnum.RV]: (value) => value,
  [ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily]: (value) => value,
  [ListingInfoFieldNamesEnum.Studio]: (value) => value,
  [ListingInfoFieldNamesEnum.OneBedroom]: (value) => value,
  [ListingInfoFieldNamesEnum.TwoBedroom]: (value) => value,
  [ListingInfoFieldNamesEnum.ThreePlusBedroom]: (value) => value,
  [ListingInfoFieldNamesEnum.UnitBreakdownForSelfStorage]: (value) => value,
  [ListingInfoFieldNamesEnum.FiveByFive]: (value) => value,
  [ListingInfoFieldNamesEnum.TenByTen]: (value) => value,
  [ListingInfoFieldNamesEnum.TenByFifteen]: (value) => value,
  [ListingInfoFieldNamesEnum.TenByTwenty]: (value) => value,
  [ListingInfoFieldNamesEnum.TenByThirty]: (value) => value,
  [ListingInfoFieldNamesEnum.RedevelopmentOpportunity]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.Operating]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.AnchorTenant]: (value: string) => value,
  [ListingInfoFieldNamesEnum.AnchorTenantCredit]: (value: string) => value,
  [ListingInfoFieldNamesEnum.AdditionalStructuralCapacity]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.AnchoredTenantLeaseExpiration]: (value: number) => TimeUtil.format(value, 'DD'), // Date
  [ListingInfoFieldNamesEnum.MaximumBoatSize]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.NumberofWetSlips]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.NumberofDryRacks]: (value: number) => StringUtil.formatNumber(value),
  [ListingInfoFieldNamesEnum.NetLease]: (value: boolean) => (value ? 'Yes' : 'No'),
  [ListingInfoFieldNamesEnum.CapRate]: (value: number) => StringUtil.formatNumber(value),
};

function format<T extends keyof TListingInfoFieldTypes, K extends TListingInfoFieldTypes[T]>(type: T, value: K): string {
  const [singular, plural] = ListingInfoFieldLabels[type];
  const isNumber = typeof Number(value) === 'number';
  return `${formatters[type](value as never)}${isNumber ? StringUtil.pluralize(singular, plural, Number(value)) : singular}`;
}

const ListingInfoFieldUtil = {
  format,
};

export default ListingInfoFieldUtil;
