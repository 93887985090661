import IComputedValues from '../models/interfaces/IComputedValues';

/** Many of the calculations depend on the results of prior calculations. Rather than invoke
   * helper functions within helper functions endlessly in the component, we pass all values
   * to a single helper below to compute all values here and then distribute them in the
   * component
   */

function calculateLoan(purchasePrice: number, downPayment: number): number {
  if (purchasePrice && downPayment) return purchasePrice - downPayment;
  return 0;
}

function calculateAnnualPayment(rate: number, term: number, loanAmount: number): number {
  const adjustedRate = rate / 10 ** 2;
  if (adjustedRate && term && loanAmount) {
    return ((loanAmount * adjustedRate) / (1 - ((1 + adjustedRate) ** -term)));
  }
  return 0;
}

function calculateAnnualCashFlow(operatingIncome: number, annualPayment: number): number {
  if (operatingIncome && annualPayment) return operatingIncome - annualPayment;
  return 0;
}

function calculateDSCR(operatingIncome: number, annualPayment): number {
  if (operatingIncome && annualPayment) return operatingIncome / annualPayment;
  return 0;
}

function calculateCapRate(operatingIncome: number, purchasePrice: number): number {
  if (operatingIncome && purchasePrice) return (operatingIncome / purchasePrice) * 10 ** 2;
  return 0;
}

function calculateROI(annualCashFlow: number, downPayment: number): number {
  if (annualCashFlow && downPayment) return (annualCashFlow / downPayment) * 10 ** 2;
  return 0;
}

function calculateDownPaymentPercent(downPayment: number, purchasePrice: number) {
  if (downPayment && purchasePrice) return (downPayment / purchasePrice) * 10 ** 2;
  return 0;
}

function computeValues(purchasePrice: number, operatingIncome: number, downPayment: number, rate: number, term: number): IComputedValues {
  const computedValues: IComputedValues = {};
  computedValues.loanAmount = calculateLoan(purchasePrice, downPayment);
  computedValues.annualPayment = calculateAnnualPayment(rate, term, computedValues.loanAmount);
  computedValues.monthlyPayment = computedValues.annualPayment / 12;
  computedValues.annualCashFlow = calculateAnnualCashFlow(operatingIncome, computedValues.annualPayment);
  computedValues.monthlyCashFlow = computedValues.annualCashFlow / 12;
  computedValues.debtServiceCoverage = calculateDSCR(operatingIncome, computedValues.annualPayment);
  computedValues.capRate = calculateCapRate(operatingIncome, purchasePrice);
  computedValues.returnOnInvestment = calculateROI(computedValues.annualCashFlow, downPayment);
  computedValues.downPaymentPercent = calculateDownPaymentPercent(downPayment, purchasePrice);
  return computedValues;
}

const ValuationUtil = {
  computeValues,
};

export default ValuationUtil;
