import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import Skeleton from 'react-loading-skeleton';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import { IVideoGraphQL } from '@biproxi/models/interfaces/IVideo';
import dynamic from 'next/dynamic';
import GoogleMap from '../GoogleMap';
import Colors from '../../styles/Colors';
import TranslucentButton from '../../elements/TranslucentButton';
import { Icons } from '../../elements/Icon';
import { ListingActions } from '../../redux/listing.redux';
import { AppState, useAppDispatch, useAppSelector } from '../../redux/store';
import { media } from '../../utils/MediaQuery';
// import PrivateListingPDPImage from '../../../public/private-listing-pdp.png';

const DynamicImageTiler = dynamic(
  () => import('./PDPImageTiler'),
  {
    ssr: false,
  },
);

const Container = styled.div<{ isModal: boolean }>`
  width: 100%;
  height: 400px;
  background: ${Colors.Grey200};
  border-radius: 16px;
  margin: 0px 0px 24px 0px;
  position: relative;
  overflow: hidden;

  ${({ isModal }) => {
    if (isModal) {
      return `
        height: 280px;
        margin: 0;
      `;
    }
    return null;
  }};

  ${media.mobile} {
    border-radius: 0px;
    height: 280px;
    margin: 0;
  }

  ${media.tablet} {
    box-sizing: border-box;
    border-radius: 0px;
    height: 280px;
    margin: 0;
    padding: 8px;
  }
`;

const ActionContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: 12px;
  left: 12px;
`;

type PDPImagesProps = {
  listing: IListingGraphQL;
  isModal?: boolean;
};

const PDPImages: React.FC<PDPImagesProps> = ({
  listing,
  isModal = false,
}) => {
  /** ******************************************************************************
*  Contentful STUFF
******************************************************************************* */

  /** Contentful - Selectors */
  const {
    contentful: {
      brandCollection: {
        companyMediaAssets,
      },
    },
  } = useAppSelector((state: AppState) => state);

  const lockedListingImage: string = companyMediaAssets.lockedListingImage.url || companyMediaAssets.lockedListingimage;

  /** ******************************************************************************
*  Contentful STUFF
******************************************************************************* */
  /** Actions */
  const dispatch = useAppDispatch();
  const previewListingVideo = (video: IVideoGraphQL) => dispatch(
    ListingActions.previewListingVideo({
      listingId: listing._id,
      video,
    }),
  );

  const previewListingImages = (fileId: string) => dispatch(
    ListingActions.previewListingImages({
      listingId: listing._id,
      fileId,
    }),
  );

  /** Render */
  const isPrivateListing = listing?.isPrivateListing;
  const image = listing?.isPrivateListing ? lockedListingImage : listing?.media?.files?.[0]?.url;

  const containerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '16px',
  };

  const lat = AddressUtil.getLatitudeAsFloat(listing?.address);
  const lng = AddressUtil.getLongitudeAsFloat(listing?.address);

  const renderMarker = (map, maps) => {
    const marker = new maps.Marker({
      position: { lat, lng },
      map,
    });
    return marker;
  };

  if (isPrivateListing) {
    return (
      <Container isModal={isModal}>
        <DynamicImageTiler
          listing={listing}
          isModal={isModal}
          lockedListingImage={lockedListingImage}
        />
      </Container>
    );
  }

  return (
    <Container isModal={isModal}>
      {image ? (
        <DynamicImageTiler listing={listing} isModal={isModal} lockedListingImage={lockedListingImage} />
      ) : (
        <>
          {(() => {
            if (!listing.address.address1) {
              return (
                <Skeleton
                  height="100%"
                  width="100%"
                />
              );
            }
            return (
              <GoogleMap
                style={containerStyle}
                center={{ lat, lng }}
                zoom={14}
                options={{
                  disableDefaultUI: true,
                  gestureHandling: 'none',
                  draggableCursor: 'default',
                }}
                onLoad={({ map, maps }) => renderMarker(map, maps)}
              />
            );
          })()}
        </>
      )}
      <ActionContainer>
        {listing?.media?.video?.fileId && (
          <TranslucentButton
            icon={Icons.VideoSolid}
            text="View video"
            onClick={() => previewListingVideo(listing.media.video)}
            margin="0px 12px 0px 0px"
          />
        )}
        {image && (
          <TranslucentButton
            icon={Icons.ImagesSolid}
            text={`View all images (${listing?.media?.files?.length})`}
            onClick={() => previewListingImages(listing?.media?.fileIds[0])}
          />
        )}
      </ActionContainer>
    </Container>
  );
};

export default PDPImages;
