import React from 'react';

/**
 * This container wraps all Next.js
 * pages and provides a place for
 * functionality that is shared between
 * pages such as hooks and effects
 */

type NextPageContainerProps = {
  children: React.ReactElement;
};

const NextPageContainer: React.FC<NextPageContainerProps> = ({ children }) => {
  React.useEffect(() => {
    window.analytics.page();
  }, []);

  return children;
};

export default NextPageContainer;
