/* eslint-disable camelcase */
import React from 'react';
import styled from '@emotion/styled';
import CherreDataUtil from '@biproxi/models/utils/CherreDataUtil';
import ICherreDataDemographics from '@biproxi/models/interfaces/ICherreDataDemographics';
import ICherreFieldValuePair from '@biproxi/models/interfaces/ICherreFieldValuePair';
import ReactTooltip from 'react-tooltip';
import { media } from '../../utils/MediaQuery';
import Colors, { ColorsType } from '../../styles/Colors';
import Flex from '../../elements/Flex';
import Text, { TextTypesEnum } from '../../elements/Text';
import Icon, { Icons } from '../../elements/Icon';
import NextUtil from '../../utils/NextUtil';
import CherreDemographicsChart from './CherreDataInsights/CherreDemographicsChart';

export interface ICherreDemographicsFieldValuePair extends ICherreFieldValuePair {
  value: number;
}

type CherreDemographicsContainerProps = {
  flexDirection?: string;
  justifyContent: string;
}

const CherreDemographicsContainer = styled.div<CherreDemographicsContainerProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent};
  width: 100%;

   ${media.mobile} {
     width: 100%;
     flex-direction: column;
  }

   ${media.tablet} {
      width: 100%;
  };

  ${media.print} {
    flex-direction: row;
  }
`;

type CherreDemographicsCardProps = {
  margin?: string;
  padding?: string;
  height?: string;
  backgroundColor?: ColorsType;
  flexDirection?: string;
}

const CherreDemographicsCard = styled.div<CherreDemographicsCardProps>`
  background: ${(props) => props.backgroundColor};
  padding: 16px;
  border-radius: 8px;
  margin: ${(props) => props.margin || 'none'};
  padding: ${(props) => props.padding || 'none'};
  height: ${(props) => props.height || 'none'};

  ${media.mobile} {
    margin: 8px 0px;
  }
`;

const CherreDemographicsChartContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-row-gap: 4px;
`;

const CherreDemographicsCardMessages = {
  'Household Income': 'Number of households in the area with income in the given range.',
  Population: 'Number of people living within the area in the given age range.',
  Employment: 'The number of people living in this area employed in the given industry.',
  Crime: 'A score that represents the risks of the given crime compared to the national average of 100.',
  Education: 'Number of people who achieved the given level of education.',
};

type CherreDemographicsCardContentProps = {
  title: string;
  data: ICherreDemographicsFieldValuePair[];
}

const DemographicsCardContent: React.FC<CherreDemographicsCardContentProps> = ({ title, data }) => {
  /* Effects */
  if (NextUtil.hasWindow()) {
    React.useLayoutEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });
  }

  /* Render */
  return (
    <>
      <Flex>
        <Text
          type={TextTypesEnum.Bold16}
          color={Colors.Black}
          margin="0px 0px 16px 0px"
        >
          {title}
        </Text>
        <Icon
          icon={Icons.InfoCircleRegular}
          color={`${Colors.Grey400}`}
          margin="4px 0px 0px 8px"
          width="16px"
          height="16px"
          transitionDuration="0.0s"
          tip={`${CherreDemographicsCardMessages[title]}`}
          data-place="top"
          data-multiline
        />
      </Flex>
      <CherreDemographicsChartContainer>
        <CherreDemographicsChart data={data} />
      </CherreDemographicsChartContainer>
    </>
  );
};

enum CherreDemographicsSections {
  HouseholdIncome = 'Household Income',
  Employment = 'Employment',
  Population = 'Population',
  Crime = 'Crime',
  Education = 'Education',
}

enum CherreDemographicsSectionsData {
  HouseholdIncome = 'income',
  Employment = 'employee',
  Population = 'population',
  Crime = 'crime',
  Education = 'education',
}

type PDPCherreDataDemographicsProps = {
    demographics: ICherreDataDemographics;
    priorYearDemographics: ICherreDataDemographics;
    justifyContent?: string;
    backgroundColor?: ColorsType;
    flexDirection?: string;
    margin?: string;
    padding?: string;
}

const PDPCherreDataDemographics: React.FC<PDPCherreDataDemographicsProps> = ({
  demographics,
  priorYearDemographics,
  justifyContent = 'space-between',
  backgroundColor = Colors.Grey50,
  flexDirection = 'row',
  margin = '8px 0',
  padding = '16px',
}) => {
  let formattedDemographics;

  if (demographics) {
    formattedDemographics = CherreDataUtil.formatCherreDemographics(demographics, priorYearDemographics);
  }

  /** Render */
  return (
    <>
      {formattedDemographics && (
      <CherreDemographicsContainer flexDirection={flexDirection} justifyContent={justifyContent}>
        <>
          {Object.keys(CherreDemographicsSections).map((key, index) => (
            <CherreDemographicsCard backgroundColor={backgroundColor} margin={margin} padding={padding} key={index}>
              <DemographicsCardContent title={CherreDemographicsSections[key]} data={formattedDemographics[CherreDemographicsSectionsData[key]]} />
            </CherreDemographicsCard>
          ))}
        </>
      </CherreDemographicsContainer>
      )}
    </>
  );
};

export default PDPCherreDataDemographics;
