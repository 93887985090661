import React from 'react';
import styled from '@emotion/styled';
import * as Polished from 'polished';
import Icon, { IconsType } from './Icon';
import Text, { TextTypesEnum } from './Text';
import Colors from '../styles/Colors';
import { media, useMobileMedia, useTabletMedia } from '../utils/MediaQuery';

const Container = styled.div<{ margin: string }>`
  display: flex;
  border-radius: 8px;
  padding: 8px 16px;
  background: ${Polished.rgba(Colors.Black, 0.75)};
  cursor: pointer;
  transition: all 0.2s;
  margin: ${({ margin }) => margin};

  ${media.mobile} {
    padding: 8px;
  }

  ${media.tablet} {
    padding: 8px;
  }

  &:hover {
    background: ${Colors.Black};
  }
`;

const IconContainer = styled.div<{ margin: string }>`
  background: ${Colors.Black};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  opacity: 0.5;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export enum TranslucentButtonTypesEnum {
  Icon = 'Icon',
  Text = 'Text',
}

type TranslucentButtonProps = {
  text?: string;
  icon: IconsType
  onClick: () => void;
  type?: TranslucentButtonTypesEnum;
  margin?: string;
}

const TranslucentButton: React.FC<TranslucentButtonProps> = ({
  text,
  icon,
  onClick,
  type = TranslucentButtonTypesEnum.Text,
  margin,
}) => {
  const isMobile = useMobileMedia();
  const isTablet = useTabletMedia();

  if (type === TranslucentButtonTypesEnum.Text) {
    return (
      <Container onClick={() => onClick()} margin={margin}>
        <Icon
          icon={icon}
          color={Colors.White}
          size={isMobile || isTablet ? 12 : 16}
          margin="0px 12px 0px 0px"
        />
        <Text type={isMobile || isTablet ? TextTypesEnum.Medium12 : TextTypesEnum.Medium16} color={Colors.White}>
          {text}
        </Text>
      </Container>
    );
  }

  return (
    <IconContainer onClick={() => onClick()} margin={margin}>
      <Icon
        icon={icon}
        color={Colors.White}
        size={14}
      />
    </IconContainer>
  );
};

export default TranslucentButton;
