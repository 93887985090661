import styled from '@emotion/styled';
import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Button, {
  ButtonSizesEnum,
  ButtonTypesEnum,
} from '../../elements/Button';
import Icon, { Icons } from '../../elements/Icon';

const Container = styled.div``;

const Grid = styled.div`
  margin: 24px 0px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 16px;
`;

const HighlightContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const ContentContainer = styled.div``;

type PDPHighlightsProps = {
  id?: string;
  listing: IListingGraphQL;
};

const PDPHighlights: React.FC<PDPHighlightsProps> = ({ id, listing }) => {
  const [highlights, setHighlights] = React.useState<number>(
    4 || listing?.highlights?.length,
  );

  return (
    <Container id={id}>
      <Text type={TextTypesEnum.Bold18} color={Colors.Black}>
        Highlights
      </Text>
      {listing?.highlights?.length > 0 && (
        <ContentContainer>
          <Grid>
            {listing.highlights.slice(0, highlights).map((highlight, index) => (
              <HighlightContainer key={index}>
                <Icon
                  icon={Icons.CircleSolid}
                  size={8}
                  color={Colors.Grey900}
                  margin="0 8px 0 0"
                />
                <Text type={TextTypesEnum.Regular16} color={Colors.Grey900}>
                  {highlight}
                </Text>
              </HighlightContainer>
            ))}
          </Grid>
          {listing?.highlights?.length > 4 && (
            <Button
              type={ButtonTypesEnum.Outline}
              size={ButtonSizesEnum.Large}
              text={
                highlights === listing.highlights.length
                  ? 'Show less'
                  : `Show all ${listing?.highlights?.length} highlights`
              }
              onClick={() => setHighlights(highlights === 4 ? listing.highlights.length : 4)}
            />
          )}
        </ContentContainer>
      )}
    </Container>
  );
};

export default PDPHighlights;
