import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import Colors from '../../styles/Colors';
import Flex, { CustomSpacer } from '../../elements/Flex';
import Text, { TextTypesEnum } from '../../elements/Text';
import FavoriteButton, { FavoriteButtonTypesEnum } from '../FavoriteButton';
import Icon, { Icons } from '../../elements/Icon';
import { media, useMobileMedia, useTabletMedia } from '../../utils/MediaQuery';
import PDPPricing from './PDPPricing';
import useUser from '../../hooks/useUser.hook';
import useIsPrivateListing from '../../hooks/useIsPrivateListing.hook';

interface ContainerProps {
  isActivePDP?: boolean;
  isPreviewPDP?: boolean;
  isModal?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: sticky;
  top: ${(props) => {
    if (props.isPreviewPDP) {
      return '60px';
    } if (props.isActivePDP) {
      return '24px';
    }
    return '80px;';
  }};
  background: ${Colors.White};
  z-index: 2;
  padding: 0px 0px 16px 0px;
  word-break: break-word;
  margin: 0px 0px;
  ${({ isModal }) => {
    if (isModal) {
      return `
        position: relative;
        top: 0;
        z-index: 0;
        padding: 0;
      `;
    }
    return null;
  }};
  ${media.mobile} {
    position: relative;
    top: 0;
    z-index: 0;
    padding: 16px;
  }
  ${media.tablet} {
    position: relative;
    top: 0;
    z-index: 0;
    padding: 16px;
  }
`;

// little hack so we don't have
// to adjust main page padding
// for the effect we want
const TopBlocker = styled.div<ContainerProps>`
  width: 100%;
  background: ${Colors.White};
  height: 24px;
  position: fixed;
  z-index: 2;
  top: ${(props) => {
    if (props.isPreviewPDP) {
      return '54px';
    } if (props.isActivePDP) {
      return '0px';
    }
    return '80px;';
  }};
`;

type PDPHeaderProps = {
  listing: IListingGraphQL;
  isActivePDP?: boolean;
  isPreviewPDP?: boolean;
  isModal?: boolean;
};

const PDPHeader: React.FC<PDPHeaderProps> = ({
  listing,
  isActivePDP = false,
  isPreviewPDP = false,
  isModal = false,
}) => {
  /** Hooks */
  const isMobile = useMobileMedia() || isModal;
  const isTablet = useTabletMedia();
  const { user } = useUser();
  const { isPrivateListing, isPrivateListingAccess } = useIsPrivateListing({
    params: {
      listing,
      user,
    },
  });

  /** Render */
  return (
    <>
      {(!isMobile && !isTablet) && (<TopBlocker isActivePDP={isActivePDP} isPreviewPDP={isPreviewPDP} />)}
      <Container data-cy="pdp-header-container" isActivePDP={isActivePDP} isPreviewPDP={isPreviewPDP} isModal={isModal}>
        <Flex justify="space-between" align="center" width="100%">
          <Flex direction="column" width="100%">
            <Text
              skWidth={isMobile || isTablet ? '100%' : 600}
              type={TextTypesEnum.Bold24}
              color={Colors.Black}
            >
              {ListingUtil.name(listing, { allButAddress1: true }, isPrivateListing && !isPrivateListingAccess)}
            </Text>
            <Flex width="100%">
              <Icon
                icon={Icons.MapMarkerAltSolid}
                color={Colors.Grey700}
                size={10}
                margin="0px 4px 0px 0px"
              />
              <Text
                skWidth={isMobile || isTablet ? 'calc(100vw - 160px)' : 400}
                type={TextTypesEnum.Regular14}
                color={Colors.Grey700}
              >
                {ListingUtil.isValidPortfolioListing(listing) ? 'Multiple addresses' : AddressUtil.formatAddress(listing.address, { address1: !listing.name || listing?.isPrivateListing, address2: listing?.isPrivateListing }) || null}
              </Text>
            </Flex>
            {(isMobile || isTablet) && (
              <>
                <CustomSpacer height="24px" />
                <PDPPricing listing={listing} isModal={isModal} />
              </>
            )}
          </Flex>
          {(!isMobile && !isTablet) && (<FavoriteButton dataCy="pdp-favorite-button" listingId={listing._id} type={FavoriteButtonTypesEnum.Text} />)}
        </Flex>
      </Container>
    </>
  );
};

export default PDPHeader;
