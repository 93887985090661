import { logger } from '@biproxi/logger';
import React from 'react';
import styled from '@emotion/styled';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import { useQuery } from '@apollo/client';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import PDPCherreDataInsights, { CherreInsightsSectionsDataEnum } from './PDPCherreDataInsights';
import PDPCherreDataDemographics from './PDPCherreDataDemographics';
import CHERRE_DATA from '../../graphql/queries/listingCherreDataFromFormattedAddressAndGeographyId.query';
import Text, { TextTypesEnum } from '../../elements/Text';
import Flex from '../../elements/Flex';
import Colors from '../../styles/Colors';
import LinkText from '../../elements/LinkText';
import { ModalTypesEnum } from '../modal/Modal';
import { useAppDispatch } from '../../redux/store';
import { AppActions } from '../../redux/app.redux';
import { media } from '../../utils/MediaQuery';
import useUser from '../../hooks/useUser.hook';
import Loader, { LoaderSizes } from '../../elements/Loader';
import NextUtil from '../../utils/NextUtil';

const Container = styled.div``;

const LoginOrSignUpCard = styled.div`
  box-sizing: border-box;
  padding: 16px;
  background: ${Colors.Grey50};
  width: 720px;
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 240px;

  ${media.mobile} {
    width: 100%;
  }

  ${media.tablet} {
    width: 100%;
  }
`;

type PDPDataInsightsProps = {
  id?: string
  listing: IListingGraphQL
};

const PDPCherreData: React.FC<PDPDataInsightsProps> = ({ id, listing }) => {
  /** Hooks */
  const { user } = useUser();

  /** Actions */
  const { data, loading } = useQuery<any, any>(CHERRE_DATA, {
    skip: !listing.address.cherreId || !user || !NextUtil.hasWindow(),
    variables: {
      params: {
        cherreTaxAssessorId: Number(listing.address.cherreId),
        cherreGeographyId: AddressUtil.cherreGeographyId(listing.address),
      },
      onError: (error) => {
        logger.error('PDPCherreData CHERRE_DATA error', error);
      },
    },
  });

  const dispatch = useAppDispatch();

  const pushLoginModal = () => {
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.Login,
        props: {},
      }),
    );
  };

  const pushRegisterModal = () => {
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.Register,
        props: {},
      }),
    );
  };

  /** Render */
  const insights = data?.cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId?.insights;
  const demographics = data?.cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId?.demographics;
  const priorYearDemographics = data?.cherreInsightsAndDemographicsDataFromTaxAssessorIdAndGeographyId?.priorYearDemographics;

  const propertyInfoSections = Object.keys(CherreInsightsSectionsDataEnum).map((insight) => insight);
  if (!NextUtil.hasWindow()) return null;
  return (
    <Container id={id}>
      <Flex direction="row">
        <Text
          type={TextTypesEnum.Bold18}
          color={Colors.Black}
          margin="0px 0px 16px 0px"
        >
          Data Insights &nbsp;
        </Text>
        <Text
          type={TextTypesEnum.Regular18}
          color={Colors.Grey500}
          margin="0px 0px 16px 0px"
        >
          (Provided by Cherre)
        </Text>
      </Flex>
      {user ? (
        <>
          {loading ? (
            <Flex flex="1" align="center" justify="center" direction="column">
              <Loader size={LoaderSizes.Medium} color={Colors.Brand700 || Colors.Blurple700} />
              <Text type={TextTypesEnum.Medium18} margin="8px 0 0">Loading Cherre insights</Text>
            </Flex>
          ) : (
            <>
              {Boolean(insights && !ListingUtil.isValidPortfolioListing(listing)) && (
              <PDPCherreDataInsights insights={insights} sections={propertyInfoSections} />
              )}
              {demographics && (
                <PDPCherreDataDemographics demographics={demographics} priorYearDemographics={priorYearDemographics} />
              )}
            </>
          )}
        </>
      ) : (
        <LoginOrSignUpCard>
          <Text
            align="center"
          >
            Data insights are only available once you
            {' '}
            <LinkText
              type={TextTypesEnum.Regular16}
              onClick={() => pushLoginModal()}
            >
              Sign In
            </LinkText>
            {' '}
            or
            {' '}
            <LinkText
              type={TextTypesEnum.Regular16}
              onClick={() => pushRegisterModal()}
            >
              Create an Account
            </LinkText>
            .
          </Text>
        </LoginOrSignUpCard>
      )}
    </Container>
  );
};

export default PDPCherreData;
