import styled from '@emotion/styled';
import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import Flex from '../../elements/Flex';
import LinkText from '../../elements/LinkText';
import PDPSectionsEnum from '../../models/enums/PDPSectionsEnum';
import useScrollHook from '../../hooks/useScroll.hook';

const Container = styled.div``;

const Grid = styled.div`
  margin: 24px 0px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 16px;
`;

type PDPPortfolioAddressesProps = {
  id?: string;
  listing: IListingGraphQL;
};

const PDPPortfolioAddresses: React.FC<PDPPortfolioAddressesProps> = ({
  id,
  listing,
}) => {
  const [portfolioAddresses, setPortfolioAddresses] = React.useState<number>(
    4 || listing?.portfolioAddresses?.length,
  );
  /** Hooks */
  const { scrollToId } = useScrollHook({
    scrollElement: window,
    offset: 80,
  });

  return (
    <Container id={id}>
      <Flex>
        <Text type={TextTypesEnum.Bold18} color={Colors.Black}>{`Properties (${listing?.portfolioAddresses?.length ?? 0} locations)`}</Text>
        <LinkText margin="0 0 0 8px" onClick={() => scrollToId(PDPSectionsEnum.Location)}>
          View on map
        </LinkText>
      </Flex>
      <Grid>
        {listing?.portfolioAddresses?.slice(0, portfolioAddresses)?.map((address, index) => (
          <Flex key={index}>
            <Text type={TextTypesEnum.Regular14Small} color={Colors.Grey900}>
              {AddressUtil.formatAddress(address)}
            </Text>
          </Flex>
        ))}
      </Grid>
      {listing?.portfolioAddresses?.length > 4 && (
      <Button
        type={ButtonTypesEnum.Outline}
        size={ButtonSizesEnum.Large}
        text={
            portfolioAddresses === listing.portfolioAddresses.length
              ? 'Show less'
              : `Show all ${listing?.portfolioAddresses?.length} addresses`
          }
        onClick={() => setPortfolioAddresses(portfolioAddresses === 4 ? listing.portfolioAddresses.length : 4)}
      />
      )}
    </Container>
  );
};

export default PDPPortfolioAddresses;
