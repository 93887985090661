import React from 'react';
import styled from '@emotion/styled';
import Colors from '../styles/Colors';
import Icon, { IconsType } from './Icon';
import BoxShadows from '../styles/BoxShadows';
import useScroll from '../hooks/useScroll.hook';
import Text, { TextTypesEnum } from './Text';
import { media } from '../utils/MediaQuery';

type ContainerProps = {
  margin: string;
  isActivePDP?: boolean;
  isPreviewPDP?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: sticky;
  top: 80px;
  width: 264px;
  padding: 16px;
  background-color: ${Colors.White};
  border: 1px solid ${Colors.Grey300};
  margin: ${(props) => props.margin};
  border-radius: 16px;
  box-sizing: border-box;
  height: fit-content;
  box-shadow: ${BoxShadows.Light};
  ${media.tablet} {
    top: 0px;
    margin: 0px 0px 0px 16px;
    width: auto;
  }
`;

const InlineContainer = styled.div<ContainerProps>`
  position: sticky;
  top: ${(props) => {
    if (props.isPreviewPDP) {
      return '120px';
    } if (props.isActivePDP) {
      return '90px';
    }
    return '152px;';
  }};
  display: flex;
  background-color: ${Colors.White};
  z-index: 10;
  border-bottom: 1px solid ${Colors.Grey300};
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
  display: none;
  }
    // Couldn't get this to scroll correctly on mobile
    // currently so hiding for now.
    // TODO: fix this
    /* ${media.mobile} {
    position: fixed;
    top: 56px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    overflow-x: scroll;
    border-bottom: none;
  } */
`;

type ButtonProps = {
  active: boolean;
  type?: ScrollNavigationTypesEnum;
}

// ##

const Button = styled.div<ButtonProps>`
  height: ${({ type }) => (type === ScrollNavigationTypesEnum.Inline ? '32px' : '40px')};
  padding: 0 8px;
  width: ${({ type }) => (type === ScrollNavigationTypesEnum.Inline ? 'fit-content' : '100%')};
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) => (props.active ? Colors.Blurple5 : Colors.White)};
  color: ${(props) => (props.active ? Colors.Brand700 || Colors.Blurple700 : Colors.Black)};
  transition: all 0.1s;
  cursor: pointer;
  margin: ${({ type }) => (type === ScrollNavigationTypesEnum.Inline ? '0px 8px 24px 0px' : '0px 0px 4px 0px')};
  &:hover {
    background-color: ${(props) => (props.active ? Colors.Blurple5 : Colors.Grey100)};
    color: ${(props) => (props.active ? Colors.Brand700 || Colors.Blurple700 : Colors.Black)};
  }
  &:active {
    background-color: ${(props) => (props.active ? Colors.Blurple5 : Colors.Grey200)};
    color: ${(props) => (props.active ? Colors.Brand700 || Colors.Blurple700 : Colors.Black)};
  }
  ${media.mobile} {
    margin: 8px 8px 8px 0px;
  }
  ${media.tablet} {
    margin: 8px 8px 8px 0px;
  }
`;

export enum ScrollNavigationTypesEnum {
  Inline = 'Inline',
  Card = 'Card',
}

type TScrollNavigationButton = {
  title: string;
  icon?: IconsType;
  elementId?: string;
}

type ScrollNavigationProps = {
  buttons: TScrollNavigationButton[];
  margin?: string;
  type?: ScrollNavigationTypesEnum;
  offset?: number;
  isActivePDP?: boolean;
  isPreviewPDP?: boolean;
};

const ScrollNavigation: React.FC<ScrollNavigationProps> = ({
  buttons,
  margin = null,
  type = ScrollNavigationTypesEnum.Card,
  offset,
  isActivePDP,
  isPreviewPDP,
}) => {
  const { scrollToId } = useScroll({
    scrollElement: window,
    defaultInitialSection: buttons[0].elementId,
    offset: type === ScrollNavigationTypesEnum.Inline ? 80 : offset,
  });

  const [activeSection, setActiveSection] = React.useState<string>(buttons[0]?.elementId);
  const [scrollFromTop, setscrollFromTop] = React.useState<number>(0);

  // contains the div elements of the divs related to the buttons on the nav bar
  const domElements = buttons?.map((button) => document.getElementById(button.elementId));

  // idk man. this is used to compare y-axis scroll values in the onscroll handler below
  let lastScroll = 0;

  /**
   * Event handler when a scroll nav button is clicked
   * Sets the 'activeSection' state to the clicked button's id
   * Scrolls to the clicked section
   */
  const handleClick = (elementId) => {
    setActiveSection(elementId);
    scrollToId(elementId);
  };

  /**
   * Tracks pixels scrolled on the y-axis from the top of the page
   */
  const handleScrollEventListener = () => {
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    const { scrollY } = window;

    // if the user is scrolling down
    if (currentScroll > 0 && lastScroll <= scrollY) {
      // for every 30 pixels scrolled, update the 'scrollFromTop' state
      if (scrollFromTop + 30 < scrollY) {
        setscrollFromTop(scrollY);
        lastScroll = currentScroll;
      }
    } else if (scrollFromTop - 30 > scrollY) {
      // if the user is scrolling up
      setscrollFromTop(scrollY);
      lastScroll = currentScroll;
    }
  };

  /**
   * When scrollFromTop is updated (every 30 pixels scrolled), determine the closest div to the top
   */
  React.useEffect(() => {
    let closestDivDistance = domElements[0]?.getBoundingClientRect().top;
    // iterate over each component related to the nav bar buttons and determine which is closest to the top
    domElements.forEach((element) => {
      const distanceFromTop = type === ScrollNavigationTypesEnum.Inline ? element?.getBoundingClientRect().top - 120 : element?.getBoundingClientRect().top - 10;
      if (distanceFromTop < Math.abs(closestDivDistance)) {
        closestDivDistance = distanceFromTop;
        setActiveSection(element?.id);
      }
    });
  }, [scrollFromTop]);

  React.useLayoutEffect(() => {
    window.addEventListener('scroll', handleScrollEventListener);
    return () => {
      window.removeEventListener('scroll', handleScrollEventListener, false);
    };
  });

  if (type === ScrollNavigationTypesEnum.Inline) {
    return (
      <InlineContainer margin={margin} isActivePDP={isActivePDP} isPreviewPDP={isPreviewPDP}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            active={button.elementId === activeSection}
            onClick={() => handleClick(button.elementId)}
            type={type}
          >
            <Text
              type={TextTypesEnum.Medium14}
              color="inherit"
            >
              {button.title}
            </Text>
          </Button>
        ))}
      </InlineContainer>
    );
  }

  return (
    <Container margin={margin}>
      {buttons.map((button, index) => (
        <Button
          key={index}
          active={button.elementId === activeSection}
          onClick={() => handleClick(button.elementId)}
          type={type}
        >
          <Icon
            icon={button.icon}
            size={16}
            color="inherit"
            margin="0 8px 0 0"
          />
          <Text
            type={TextTypesEnum.Medium14}
            color="inherit"
          >
            {button.title}
          </Text>
        </Button>
      ))}
    </Container>
  );
};

export default ScrollNavigation;
