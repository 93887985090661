import React from 'react';
import styled from '@emotion/styled';
import CherreDataUtil from '@biproxi/models/utils/CherreDataUtil';
// import { useAppSelector } from 'apps/web/src/redux/store';
import Skeleton from 'react-loading-skeleton';
import { AppState, useAppSelector } from '../../../redux/store';
import { ICherreDemographicsFieldValuePair } from '../PDPCherreDataDemographics';
import Flex from '../../../elements/Flex';
import Text, { TextTypesEnum } from '../../../elements/Text';
import { media } from '../../../utils/MediaQuery';
import Colors from '../../../styles/Colors';

const CherreDemographicsChartBar = styled.div<{ width?: number, backgroundColor?: string }>`
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width}px;
  height: 13px;
  ${media.print}{
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  };
`;

const SkeletonContainer = styled.div`

`;

type CherreDemographicsChartProps = {
    data: ICherreDemographicsFieldValuePair[];
  }

const CherreDemographicsChart: React.FC<CherreDemographicsChartProps> = ({ data }) => {
  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */
  /** Contentful - Redux */
  const {
    contentful: {
      brandCollection: {
        colors,
        fetched,
      },
    },
  } = useAppSelector((state: AppState) => state);
  const [barLoad, setBarLoad] = React.useState(true);

  const barColor = colors.Brand700 || colors.Blurple700;

  React.useEffect(() => {
    if (fetched) {
      setBarLoad(false);
    }
  }, [fetched]);

  /** ******************************************************************************
  *  Contentful STUFF
  ******************************************************************************* */
  const maxValue = Math.max(...data.slice(1)?.map((dataPoint) => dataPoint.value));
  const yearOverYearChange = CherreDataUtil.formatYearOverYearChangeForCherreDemographicsData(data[0]?.value[0], data[0]?.value[1]);

  return (
    <>
      {Array.isArray(data[0].value) && (
        <Flex
          direction="row"
        >
          <Text width="144px" type={TextTypesEnum.Regular12}>{data[0]?.field}</Text>
          <Text margin="0px 4px 0px 0px" type={TextTypesEnum.Regular12}>{data[0]?.value[0].toLocaleString()}</Text>
          {(yearOverYearChange >= 0) ? (
            <Text color={Colors.Green500} type={TextTypesEnum.Regular12}>
              +
              {yearOverYearChange.toFixed(2)}
              % YOY
            </Text>
          ) : (
            <Text color={Colors.Red500} type={TextTypesEnum.Regular12}>
              {yearOverYearChange.toFixed(2)}
              % YOY
            </Text>
          )}
        </Flex>
      )}
      {data.slice(1)?.map((property, index) => (
        <Flex key={index} direction="row">
          <Text
            type={TextTypesEnum.Regular12}
            color={Colors.Black}
            width="144px"
          >
            {property.field}
          </Text>
          {barLoad
            ? (
              <SkeletonContainer style={{ width: (property.value / maxValue) * 140, height: '13px' }}>
                <Skeleton
                  width="100%"
                  height="100%"
                  style={{ backgroundColor: Colors.Grey300, borderRadius: '0' }}
                />
              </SkeletonContainer>
            )
            : <CherreDemographicsChartBar backgroundColor={barColor} key={property.value} width={(property.value / maxValue) * 140} />}
          <Text
            type={TextTypesEnum.Regular12}
            color={Colors.Black}
            width="32px"
          >
       &nbsp;
            {property.value}
          </Text>
        </Flex>
      ))}
    </>
  );
};

export default CherreDemographicsChart;
