import React from 'react';
import styled from '@emotion/styled';
import ICherreFieldValuePair from '@biproxi/models/interfaces/ICherreFieldValuePair';
import Text, { TextTypesEnum } from '../../../elements/Text';
import Colors from '../../../styles/Colors';
import { media } from '../../../utils/MediaQuery';
import { DataInsightFormatEnum, FieldValuePair } from '../PDPCherreDataInsights';

type CardDataGridProps = {
    dataFormat?: DataInsightFormatEnum
}

const CherreInsightsCardDataGrid = styled.div<CardDataGridProps>`
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: ${({ dataFormat }) => {
    switch (dataFormat) {
      case DataInsightFormatEnum.List:
        return '1fr';
      case DataInsightFormatEnum.Card:
        return 'repeat(3, 1fr)';
      default:
        return 'repeat(3, 1fr)';
    }
  }};
  row-gap: ${({ dataFormat }) => {
    switch (dataFormat) {
      case DataInsightFormatEnum.List:
        return '16px';
      case DataInsightFormatEnum.Card:
        return '32px';
      default:
        return '32px';
    }
  }};
  column-gap: 32px;
  // height: 100%;
 ${media.mobile} {
   grid-template-columns: repeat(1, 1fr);
 };
`;

export enum CardDataGridColumns {
    OneColumn = 'One Column',
    TwoColumns = 'Two Columns',
    ThreeColumns = 'Three Columns'
}

type SectionProps = {
    title: string;
    data: ICherreFieldValuePair[];
    dataFormat?: DataInsightFormatEnum;
};

const CherreGenericInsights: React.FC<SectionProps> = ({
  title,
  data,
  dataFormat = DataInsightFormatEnum.Card,
}) => (
  <>
    <Text
      type={TextTypesEnum.Bold16}
      color={Colors.Black}
      margin="0px 0px 16px 0px"
    >
      {title}
    </Text>
    <CherreInsightsCardDataGrid dataFormat={dataFormat}>
      {data?.map((dataPoint, index) => <FieldValuePair key={index} field={dataPoint.field} value={dataPoint.value} />)}
    </CherreInsightsCardDataGrid>
  </>
);

export default CherreGenericInsights;
