import React from 'react';
import styled from '@emotion/styled';
import IListingContact from '@biproxi/models/interfaces/IListingContact';
import StringUtil from '@biproxi/models/utils/StringUtil';
import Colors from '../styles/Colors';
import Text, { TextTypesEnum } from '../elements/Text';
import BoxShadows from '../styles/BoxShadows';
import ProfileImage, { ProfileImageTypes } from './ProfileImage';
import Flex from '../elements/Flex';
import LinkText from '../elements/LinkText';
import Icon, { Icons } from '../elements/Icon';
import { AppActions } from '../redux/app.redux';
import { ModalTypesEnum } from './modal/Modal';
import { ConfirmChangeModalTypesEnum } from './modal/ConfirmChangeModal';
import { ListingActions } from '../redux/listing.redux';
import { useAppDispatch } from '../redux/store';
import useUserHook from '../hooks/useUser.hook';

const CreateListingContainer = styled.div`
  width: 100%;
  border: 1px solid ${Colors.Grey400};
  border-radius: 4px;
  border: 1px solid ${Colors.Grey300};
  background: ${Colors.White};
  display: flex;
  position: relative;
  padding: 16px;
  align-items: center;
  box-sizing: border-box;
  transition: all 0.2s;
  height: 175px;

  &:hover {
    border: 1px solid ${Colors.Grey400};
    box-shadow: ${BoxShadows.Light};
    background: ${Colors.Grey50};
  }

  &:active {
    border: 1px solid ${Colors.Grey400};
    background: ${Colors.White};
    box-shadow: ${BoxShadows.Darker};
  }
`;

const PdpContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ContactContentFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 250px;
  height: 100%;

  &, & > *, > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ActionOverlay = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;

 type ListingContactBoxProps = {
   contact: IListingContact;
   index?: number;
   contacts?: IListingContact[];
   isPdpPage?: boolean;
   handleDefaultContactDeletion?: (hidden: boolean) => void;
 };

const ListingContactBox: React.FC<ListingContactBoxProps> = ({
  contact,
  index,
  contacts,
  isPdpPage,
  handleDefaultContactDeletion,
}) => {
  /** Hooks */
  const { user } = useUserHook();

  /** State */
  const isDefaultContact = Boolean(contact._id === user?._id);

  /* Actions */
  const dispatch = useAppDispatch();

  const popModal = () => dispatch(AppActions.popModal());

  const deleteContact = () => {
    const c = [...contacts];
    c.splice(index, 1);
    dispatch(ListingActions.setListingContacts({ contacts: c }));
    popModal();

    if (isDefaultContact) {
      handleDefaultContactDeletion(true);
    }
  };

  const pushDeleteContactModal = () => (
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.ConfirmChange,
        props: {
          title: 'Delete Contact',
          text: 'Are you sure? You cannot undo this action afterwards.',
          buttonText: 'Delete',
          type: ConfirmChangeModalTypesEnum.Warning,
          confirm: () => deleteContact(),
        },
      }),
    )
  );

  const pushEditContactModal = () => (
    dispatch(
      AppActions.pushModal({
        type: ModalTypesEnum.AddOrEditListingContact,
        props: {
          contacts,
          contact,
          index,
          isEdit: true,
        },
      }),
    )
  );

  /** Render */
  const contactFirstName = contact.name.split(' ')[0];

  return (
    <>
      {isPdpPage ? (
        <PdpContainer>
          <Flex direction="column" align="flex-end">
            <ProfileImage margin="0px 16px 0px 0px" type={ProfileImageTypes.Large} contact={contact} />
          </Flex>
          <ContactContentFlex>
            <Text type={TextTypesEnum.Medium16}>{contact.name}</Text>
            {contact.title && (
              <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>{contact.title}</Text>
            )}
            {contact.company && (
              <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>{contact?.company}</Text>
            )}
            <Flex>
              <Icon
                size={14}
                icon={Icons.EnvelopeRegular}
                margin="0px 8px 0px 0px"
              />
              <LinkText
                type={TextTypesEnum.Regular14}
                onClick={() => window.open(`mailto:${contact.email}`)}
              >
                {`Email ${contactFirstName}`}
              </LinkText>
            </Flex>
            <Flex>
              <Icon
                size={14}
                icon={Icons.PhoneRegular}
                margin="0px 8px 0px 0px"
              />
              <LinkText
                type={TextTypesEnum.Regular14}
                onClick={() => window.open(`tel:${contact.phoneNumber}`)}
              >
                {StringUtil.formatPhoneNumber(contact.phoneNumber)}
              </LinkText>
            </Flex>
            {contact.license && (
            <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
              License:
              {' '}
              {contact.license}
            </Text>
            )}
          </ContactContentFlex>
        </PdpContainer>
      ) : (
        <CreateListingContainer>
          <Flex height="100%" direction="column" justify="center">
            <ProfileImage type={ProfileImageTypes.Large} contact={contact} />
          </Flex>
          <ContactContentFlex>
            <Text type={TextTypesEnum.Medium16}>{contact.name}</Text>
            {contact.title && (
              <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>{contact.title}</Text>
            )}
            {contact.company && (
              <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>{contact?.company}</Text>
            )}
            <Flex>
              <Icon
                size={14}
                icon={Icons.EnvelopeRegular}
                margin="0px 8px 0px 0px"
              />
              <LinkText
                type={TextTypesEnum.Regular14}
                onClick={() => window.open(`mailto:${contact.email}`)}
              >
                {contact.email}
              </LinkText>
            </Flex>
            <Flex>
              <Icon
                size={14}
                icon={Icons.PhoneRegular}
                margin="0px 8px 0px 0px"
              />
              <LinkText
                type={TextTypesEnum.Regular14}
                onClick={() => window.open(`tel:${contact.phoneNumber}`)}
              >
                {StringUtil.formatPhoneNumber(contact.phoneNumber)}
              </LinkText>
            </Flex>
            {contact.license && (
            <Text type={TextTypesEnum.Regular14} color={Colors.Grey500}>
              License:
              {' '}
              {contact.license}
            </Text>
            )}
          </ContactContentFlex>
          <ActionOverlay>
            <Flex align="center">
              <Icon
                icon={Icons.PenRegular}
                color={Colors.Grey300}
                hoverColor={Colors.Brand700 || Colors.Blurple700}
                size={18}
                onClick={() => pushEditContactModal()}
                margin={contacts.length > 1 ? '0px 12px 0px 0px' : '0px 0px 0px 0px'}
              />
              {contacts.length > 1 && (
                <Icon
                  icon={Icons.TimesRegular}
                  color={Colors.Grey300}
                  hoverColor={Colors.Brand700 || Colors.Blurple700}
                  size={24}
                  onClick={() => pushDeleteContactModal()}
                />
              )}
            </Flex>
          </ActionOverlay>
        </CreateListingContainer>
      )}
    </>
  );
};

export default ListingContactBox;
