import React from 'react';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import { ModalTypesEnum } from '../modal/Modal';

type DeleteFromWebflowButtonProps = {
  listingId: string;
};

const DeleteFromWebflowButton: React.FC<DeleteFromWebflowButtonProps> = ({
  listingId,
}) => {
  /* Actions */
  const dispatch = useAppDispatch();

  const pushOptionModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.DeleteFromWebflowModal,
      props: {
        listingId,
      },
    }),
  );

  return (
    <Button
      text="Delete from Webflow"
      type={ButtonTypesEnum.Primary}
      size={ButtonSizesEnum.Large}
      margin="16px 0 0"
      onClick={pushOptionModal}
    />
  );
};

export default DeleteFromWebflowButton;
