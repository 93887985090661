import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import PDPSectionsEnum from '../models/enums/PDPSectionsEnum';
import PDPDocuments from '../components/pdp/PDPDocuments';
import PDPDescription from '../components/pdp/PDPDescription';
import PDPContacts from '../components/pdp/PDPContacts';
import PDPValuationCalculator from '../components/pdp/PDPValuationCalculator';
import PDPLocation from '../components/pdp/PDPLocation';
import PDPDetails from '../components/pdp/PDPDetails';
import PDPHighlights from '../components/pdp/PDPHighlights';
import PDPCherreData from '../components/pdp/PDPCherreData';
import PDPPortfolioAddresses from '../components/pdp/PDPPortfolioAddresses';

/** Possibly  change logic to render PDP contacts
 * Or, change requirements for publishing a listing: at least one contact must have a license.
 * Its possible to not have a user with a license if default contact is user, none are added, and user does not have license
 */

type FilteredPDP = {
  name: string;
  component: React.FC;
}

const getFilteredPDPSections = (listing: IListingGraphQL, isModal = false): FilteredPDP[] => {
  const array = [];
  if (ListingUtil.isValidPortfolioListing(listing)) {
    array.push({
      name: PDPSectionsEnum.PortfolioAddresses,
      component: <PDPPortfolioAddresses id={PDPSectionsEnum.PortfolioAddresses} listing={listing} />,
    });
  }
  if (listing?.assetClass) {
    array.push({
      name: PDPSectionsEnum.Details,
      component: <PDPDetails id={PDPSectionsEnum.Details} listing={listing} isModal={isModal} />,
    });
  }
  if (ListingUtil?.vaultFiles(listing)?.length > 0) {
    array.push({
      name: PDPSectionsEnum.Documents,
      component: <PDPDocuments id={PDPSectionsEnum.Documents} listing={listing} />,
    });
  }
  if (listing?.highlights?.length > 0) {
    array.push({
      name: PDPSectionsEnum.Highlights,
      component: <PDPHighlights id={PDPSectionsEnum.Highlights} listing={listing} />,
    });
  }
  if (listing?.description) {
    array.push({
      name: PDPSectionsEnum.Description,
      component: <PDPDescription id={PDPSectionsEnum.Description} listing={listing} />,
    });
  }
  if (listing?.user) {
    array.push({
      name: PDPSectionsEnum.Contacts,
      component: <PDPContacts id={PDPSectionsEnum.Contacts} listing={listing} />,
    });
  }
  if (listing?.guidance?.askingPrice) {
    array.push({
      name: PDPSectionsEnum.Valuation,
      component: <PDPValuationCalculator id={PDPSectionsEnum.Valuation} listing={listing} />,
    });
  }
  if (AddressUtil.getLatitude(listing?.address) || AddressUtil.getLongitude(listing?.address)) {
    array.push({
      name: PDPSectionsEnum.Location,
      component: <PDPLocation id={PDPSectionsEnum.Location} listing={listing} isModal={isModal} />,
    });
  }
  if (listing?.address?.cherreId) {
    array.push({
      name: PDPSectionsEnum.DataInsights,
      component: <PDPCherreData id={PDPSectionsEnum.DataInsights} listing={listing} />,
    });
  }
  return array;
};

export default getFilteredPDPSections;
