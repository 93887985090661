import ListingInfoFieldNamesEnum from '../enums/ListingInfoFieldNamesEnum';

const ListingInfoFieldLabels = {
  [ListingInfoFieldNamesEnum.AccruedInterestAmount]: ['', ''],
  [ListingInfoFieldNamesEnum.AdditionalStructuralCapacity]: ['', ''],
  [ListingInfoFieldNamesEnum.ADR]: ['', ''],
  [ListingInfoFieldNamesEnum.AgeRestriction]: ['', ''],
  [ListingInfoFieldNamesEnum.AmortizationRate]: ['', ''],
  [ListingInfoFieldNamesEnum.Anchored]: ['', ''],
  [ListingInfoFieldNamesEnum.AnchoredTenantLeaseExpiration]: ['', ''],
  [ListingInfoFieldNamesEnum.AnchorTenant]: ['', ''],
  [ListingInfoFieldNamesEnum.AnchorTenantCredit]: ['', ''],
  [ListingInfoFieldNamesEnum.AnnualRent]: ['', ''],
  [ListingInfoFieldNamesEnum.AnnualRentIncrease]: ['', ''],
  [ListingInfoFieldNamesEnum.AssetClass]: ['', ''],
  [ListingInfoFieldNamesEnum.AverageRentPerPad]: ['', ''],
  [ListingInfoFieldNamesEnum.BoatRVStorage]: ['', ''],
  [ListingInfoFieldNamesEnum.BuildingClass]: ['', ''],
  [ListingInfoFieldNamesEnum.CommunityType]: ['', ''],
  [ListingInfoFieldNamesEnum.ConservationEasement]: ['', ''],
  [ListingInfoFieldNamesEnum.Credit]: ['', ''],
  [ListingInfoFieldNamesEnum.CreditRatingAgency]: ['', ''],
  [ListingInfoFieldNamesEnum.CreditRating]: ['', ''],
  [ListingInfoFieldNamesEnum.DefaultInterest]: ['', ''],
  [ListingInfoFieldNamesEnum.DefaultStatus]: ['', ''],
  [ListingInfoFieldNamesEnum.DevelopableLand]: ['', ''],
  [ListingInfoFieldNamesEnum.EconomicScale]: ['', ''],
  [ListingInfoFieldNamesEnum.ElectricBilledDirect]: ['', ''],
  [ListingInfoFieldNamesEnum.EnvironmentalSurvey]: ['', ''],
  [ListingInfoFieldNamesEnum.FeeSimple]: ['', ''],
  [ListingInfoFieldNamesEnum.GradeLevelDoors]: ['', ''],
  [ListingInfoFieldNamesEnum.Guarantor]: ['', ''],
  [ListingInfoFieldNamesEnum.InterestRate]: ['', ''],
  [ListingInfoFieldNamesEnum.LabToOfficeSqFtRatio]: ['', ''],
  [ListingInfoFieldNamesEnum.LeaseCommencementDate]: ['', ''],
  [ListingInfoFieldNamesEnum.LeaseExpiration]: ['', ''],
  [ListingInfoFieldNamesEnum.LeinPosition]: ['', ''],
  [ListingInfoFieldNamesEnum.LocationType]: ['', ''],
  [ListingInfoFieldNamesEnum.MaturityDate]: ['', ''],
  [ListingInfoFieldNamesEnum.NAICS]: ['', ''],
  [ListingInfoFieldNamesEnum.NetLease]: ['', ''],
  [ListingInfoFieldNamesEnum.Occupancy]: ['%', '%'],
  [ListingInfoFieldNamesEnum.OnCampus]: ['', ''],
  [ListingInfoFieldNamesEnum.Operating]: ['', ''],
  [ListingInfoFieldNamesEnum.OperatingForChildcare]: ['', ''],
  [ListingInfoFieldNamesEnum.Ownership]: ['', ''],
  [ListingInfoFieldNamesEnum.RedevelopmentOpportunity]: ['', ''],
  [ListingInfoFieldNamesEnum.RevPAR]: ['', ''],
  [ListingInfoFieldNamesEnum.SIC]: ['', ''],
  [ListingInfoFieldNamesEnum.Tenancy]: ['', ''],
  [ListingInfoFieldNamesEnum.TenantName]: ['', ''],
  [ListingInfoFieldNamesEnum.Type]: ['', ''],
  [ListingInfoFieldNamesEnum.UnitBreakdownForMobileHome]: ['', ''],
  [ListingInfoFieldNamesEnum.UnitBreakdownForMultiFamily]: ['', ''],
  [ListingInfoFieldNamesEnum.UnitBreakdownForSelfStorage]: ['', ''],
  [ListingInfoFieldNamesEnum.UnitPriceFor10X10]: ['', ''],
  [ListingInfoFieldNamesEnum.UPB]: ['', ''],
  [ListingInfoFieldNamesEnum.WaterPaidbyPark]: ['', ''],
  [ListingInfoFieldNamesEnum.WeightedAverageLeaseTerm]: ['', ''],
  [ListingInfoFieldNamesEnum.YearBuilt]: ['', ''],
  [ListingInfoFieldNamesEnum.YearRenovated]: ['', ''],
  [ListingInfoFieldNamesEnum.Zoning]: ['', ''],
  [ListingInfoFieldNamesEnum.BuildingSqFt]: [' sq. foot', ' sq. feet'],
  [ListingInfoFieldNamesEnum.CeilingHeight]: [' foot', ' feet'],
  [ListingInfoFieldNamesEnum.ClearHeight]: [' foot', ' feet'],
  [ListingInfoFieldNamesEnum.KickOutClause]: [' day', ' days'],
  [ListingInfoFieldNamesEnum.LoadingDocks]: [' dock', ' docks'],
  [ListingInfoFieldNamesEnum.LotSize]: [' acre', ' acres'],
  [ListingInfoFieldNamesEnum.MaximumBoatSize]: [' foot', ' feet'],
  [ListingInfoFieldNamesEnum.NumberofDryRacks]: [' dry rack', ' dry racks'],
  [ListingInfoFieldNamesEnum.NumberOfFloors]: [' floor', ' floors'],
  [ListingInfoFieldNamesEnum.NumberofLeaseRenewals]: [' renewal', ' renewals'],
  [ListingInfoFieldNamesEnum.NumberofLevels]: [' level', ' levels'],
  [ListingInfoFieldNamesEnum.NumberofPads]: [' pad', ' pads'],
  [ListingInfoFieldNamesEnum.NumberofRooms]: [' room', ' rooms'],
  [ListingInfoFieldNamesEnum.NumberofSpaces]: [' space', ' spaces'],
  [ListingInfoFieldNamesEnum.NumberofTenants]: [' tenant', ' tenants'],
  [ListingInfoFieldNamesEnum.NumberofUnits]: [' unit', ' units'],
  [ListingInfoFieldNamesEnum.NumberofUnitsSpaces]: [' unit/space', ' units/spaces'],
  [ListingInfoFieldNamesEnum.NumberofWetSlips]: [' slip', ' splips'],
  [ListingInfoFieldNamesEnum.ParkingSpaces]: [' space', ' spaces'],
  [ListingInfoFieldNamesEnum.RemainingTotalTerm]: [' year', ' years'],
  [ListingInfoFieldNamesEnum.TotalSqFt]: [' sq. foot', ' sq. feet'],
  [ListingInfoFieldNamesEnum.Singlewide]: [' singlewide', ' singlewides'],
  [ListingInfoFieldNamesEnum.Doublewide]: [' doublewide', ' doublewides'],
  [ListingInfoFieldNamesEnum.Triplewide]: [' triplewide', ' triplewides'],
  [ListingInfoFieldNamesEnum.RV]: [' RV', ' RVs'],
  [ListingInfoFieldNamesEnum.Studio]: [' unit', ' units'],
  [ListingInfoFieldNamesEnum.OneBedroom]: [' unit', ' units'],
  [ListingInfoFieldNamesEnum.TwoBedroom]: [' unit', ' units'],
  [ListingInfoFieldNamesEnum.ThreePlusBedroom]: [' unit', ' units'],
  [ListingInfoFieldNamesEnum.FiveByFive]: [' 5x5', ' 5xs5s'],
  [ListingInfoFieldNamesEnum.TenByTen]: [' 10x10', ' 10x10s'],
  [ListingInfoFieldNamesEnum.TenByFifteen]: [' 10x15', ' 10x15s'],
  [ListingInfoFieldNamesEnum.TenByTwenty]: [' 10x20', ' 10x20s'],
  [ListingInfoFieldNamesEnum.TenByThirty]: [' 10x30', ' 10x30s'],
  [ListingInfoFieldNamesEnum.YearsPerRenewalOption]: [' year', ' years'],
  [ListingInfoFieldNamesEnum.CapRate]: ['%', '%'],
};

export default ListingInfoFieldLabels;
