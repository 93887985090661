import React from 'react';
import Button, { ButtonSizesEnum, ButtonTypesEnum } from '../../elements/Button';
import { AppActions } from '../../redux/app.redux';
import { useAppDispatch } from '../../redux/store';
import { ModalTypesEnum } from '../modal/Modal';

type PushToWebflowButtonProps = {
  listingId: string;
};

const PushToWebflowButton: React.FC<PushToWebflowButtonProps> = ({
  listingId,
}) => {
  /* Actions */
  const dispatch = useAppDispatch();

  const pushPushToWebflowModal = () => dispatch(
    AppActions.pushModal({
      type: ModalTypesEnum.PushToWebflowModal,
      props: {
        listingId,
      },
    }),
  );

  /** Render */
  return (
    <Button
      text="Push to Webflow"
      type={ButtonTypesEnum.Primary}
      size={ButtonSizesEnum.Large}
      margin="16px 0 0"
      onClick={pushPushToWebflowModal}
    />
  );
};

export default PushToWebflowButton;
