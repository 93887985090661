import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import React from 'react';
import Divider from '../../elements/Divider';
import Text, { TextTypesEnum } from '../../elements/Text';
import Colors from '../../styles/Colors';
import DeleteFromWebflowButton from './DeleteFromWebflowButton';
import PushToWebflowButton from './PushToWebflowButton';

type SuperAdminPDPToolsProps = {
  listing: IListingGraphQL;
};

const SuperAdminPDPTools: React.FC<SuperAdminPDPToolsProps> = ({
  listing,
}) => (
  <>
    <Divider margin="24px 0px 32px 0px" />
    <Text
      type={TextTypesEnum.Bold18}
      color={Colors.Black}
      margin="0px 0px 16px 0px"
    >
      Super Admin Tools
    </Text>
    <PushToWebflowButton listingId={listing._id} />
    <DeleteFromWebflowButton listingId={listing._id} />
  </>
);

export default SuperAdminPDPTools;
