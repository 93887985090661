import styled from '@emotion/styled';
import React from 'react';
import { IListingGraphQL } from '@biproxi/models/interfaces/IListing';
import AddressUtil from '@biproxi/models/utils/AddressUtil';
import ListingUtil from '@biproxi/models/utils/ListingUtil';
import { fitBounds } from 'google-map-react';
import GoogleMap from '../GoogleMap';
import Colors from '../../styles/Colors';
import Text, { TextTypesEnum } from '../../elements/Text';
import BoxShadows from '../../styles/BoxShadows';
import { media } from '../../utils/MediaQuery';

const Container = styled.div``;

type Modal = {
  isModal?: boolean
}

const MapContainer = styled.div<Modal>`
  position: relative;
  margin-top: 16px;
  width: 100%;
  height: 304px;

  ${({ isModal }) => {
    if (isModal) {
      return `
        height: 400px;
      `;
    }
    return null;
  }};

  ${media.mobile} {
    height: 400px;
  }
`;

const AddressContainer = styled.div<Modal>`
  background: ${Colors.White};
  border-radius: 4px;
  bottom: 16px;
  left: 16px;
  position: absolute;
  padding: 8px 12px;
  box-shadow: ${BoxShadows.Hard};

  ${({ isModal }) => {
    if (isModal) {
      return `
        max-width: 60%;
      `;
    }
    return null;
  }};

  ${media.mobile} {
    max-width: 60%;
  }
`;

type PDPLocationProps = {
  id?: string;
  listing: IListingGraphQL;
  isModal?: boolean;
};

const PDPLocation: React.FC<PDPLocationProps> = ({
  id,
  listing,
  isModal = false,
}) => {
  /** State */
  const [curSelectedAddress, setCurSelectedAddress] = React.useState(null);
  const containerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '4px',
  };

  const lat = AddressUtil.getLatitudeAsFloat(listing?.address);
  const lng = AddressUtil.getLongitudeAsFloat(listing?.address);

  const [mapOptions, setMapOptions] = React.useState({
    location: { lat, lng },
    zoom: 13,
  });

  const handleMapLoad = (map, maps) => {
    let markers;
    // eslint-disable-next-line no-undef
    const bounds = new google.maps.LatLngBounds();
    // Could probably be refactored for better readability
    if (ListingUtil?.isValidPortfolioListing(listing)) {
      markers = listing.portfolioAddresses?.map((address) => {
        const lat = AddressUtil.getLatitudeAsFloat(address);
        const lng = AddressUtil.getLongitudeAsFloat(address);
        const mark = new maps.Marker({
          position: { lat, lng },
          map,
        });
        mark.addListener('click', () => {
          setCurSelectedAddress(address);
          setMapOptions({
            location: { lat, lng },
            zoom: mapOptions.zoom === 13.98 ? 13.99 : 13.98,
          });
        });
        // eslint-disable-next-line no-undef
        const newPoint = new google.maps.LatLng(lat, lng);
        bounds.extend(newPoint);
        return mark;
      });

      // If mulitple addresses are the same lat lng we just have one marker and set it like there is only 1 address
      // or we run into issues
      if (AddressUtil.doAllAddressesHaveExactSameLocation(listing?.portfolioAddresses)) {
        setMapOptions({
          location: { lat, lng },
          zoom: mapOptions.zoom === 13.98 ? 13.99 : 13.98,
        });
      } else {
      // Semms to work fine in all cases but I don't like that it's hardcoded.
        const size = {
          width: 673, // Map width in pixels
          height: 304, // Map height in pixels
        };

        const newBounds = {
          ne: {
            lat: bounds?.getNorthEast().lat(),
            lng: bounds?.getNorthEast().lng(),
          },
          sw: {
            lat: bounds?.getSouthWest().lat(),
            lng: bounds?.getSouthWest().lng(),
          },
        };

        const { center, zoom } = fitBounds(newBounds, size);
        setMapOptions({
          location: center,
          zoom: zoom - 1,
        });
      }
    } else {
      markers = new maps.Marker({
        position: { lat, lng },
        map,
      });
      markers.addListener('click', () => {
        setMapOptions({
          location: { lat, lng },
          zoom: mapOptions.zoom === 13.98 ? 13.99 : 13.98,
        });
      });
    }

    return markers;
  };

  return (
    <Container id={id}>
      <Text type={TextTypesEnum.Bold18} color={Colors.Black}>Location</Text>
      <MapContainer isModal={isModal}>
        {(() => (
          <GoogleMap
            center={mapOptions.location}
            zoom={mapOptions.zoom}
            onLoad={({ map, maps }) => handleMapLoad(map, maps)}
            options={{
              mapTypeControl: true,
            }}
            style={containerStyle}
          />
        ))()}
        <AddressContainer
          isModal={isModal}
          // onClick={() => {
          //   setMapOptions({
          //     location: { lat, lng },
          //     zoom: mapOptions.zoom === 13.98 ? 13.99 : 13.98, // little hack to get the zoom to work
          //   });
          // }}
        >
          <Text type={TextTypesEnum.Regular16} color={Colors.Black}>{AddressUtil.formatAddress(curSelectedAddress || listing?.address)}</Text>
        </AddressContainer>
      </MapContainer>
    </Container>
  );
};

export default PDPLocation;
