import React from 'react';
import UrlParams from '../models/interfaces/UrlParams';
import * as UrlUtil from '../utils/UrlUtil';

export type UseScroll = {
  section: string;
  scrollToId: (elementId: string) => void;
};

export type UseScrollParams = {
  scrollElement?: any;
  defaultInitialSection?: string;
  offset?: number;
}

type UseScrollHook = (params?: UseScrollParams) => UseScroll;

const useScrollHook: UseScrollHook = (params: UseScrollParams = {
  scrollElement: window,
  defaultInitialSection: '',
  offset: 0,
}) => {
  const hasWindow = typeof window !== 'undefined';

  const query = hasWindow
    ? UrlUtil.parse(window.location.toString()).query
    : {};

  const { section: initialSection = '' } = query as UrlParams;

  const [section, setSection] = React.useState(initialSection || params.defaultInitialSection);

  const scrollToIdCallback = (elementId: string) => {
    setSection(elementId);
    UrlUtil.setQueryString({ section: elementId });
    const element = document.getElementById(elementId);
    let top = element?.offsetTop || 0;
    top -= (params?.offset || 0);
    setTimeout(() => {
      params?.scrollElement?.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top,
      });
    }, 100);
  };

  const scrollToId = React.useCallback(scrollToIdCallback, [section]);

  React.useEffect(() => {
    scrollToIdCallback(initialSection);
  }, []);

  return {
    section,
    scrollToId,
  };
};

export default useScrollHook;
